import React, { Component } from 'react'
import './index.css';
export default class RegisterAgreement extends Component {
    render() {
        return (          
            <div className="register"> 
              <p className="protocol-paragraph" style={{ fontWeight: 'bold' }}>特别提示：您在同意协议前应完整、仔细地阅读本协议，您同意并继续支付将被视为完全理解并接受以下全部协议条款。您在社工锐题库（下称“APP”）上同意本支付协议后，即成为本支付协议之授权人，该授权即刻发生效力。您如果不同意以下协议条款，请勿进行后续操作。</p>
              <p className="protocol-paragraph">一、授权人授权北京文睿网络科技有限公司通过第三方支付平台划扣结算费用。结算费用是指授权人通过 APP 提交的订单上记载的总费用。</p>
              <p className="protocol-paragraph">二、在授权人成功提交订单后，北京文睿网络科技有限公司依照 APP 上公布的收费规则计算结算费用。授权人应在5分钟内根据页面指示完成支付。</p>
              <p className="protocol-paragraph">三、如因授权人在第三方支付平台中的支付账户被锁定、盗用、被往来银行拒绝或账户无效等，以致支付账户付款失败时，北京文睿网络科技有限公司有权中止与授权人之间的服务协议。</p>
              <p className="protocol-paragraph">四、授权人如有冒用、盗用他人支付账户之行为，须自行承担法律责任。</p>
              <p className="protocol-paragraph" style={{ fontWeight: 'bold' }}>五、如您使用第三方支付平台完成支付，第三方会通过 SDK 收集您的以下信息完成支付，请您查看第三方隐私政策了解您的信息收集和使用情形。可能涉及到的第三方和收集的信息如下：</p>
              <p className="protocol-section">微信支付：
                <br/>SDK 的来源及联系方式：深圳市腾讯计算机系统有限公司，深圳市南山区粤海街道麻岭社区科技中一路腾讯大厦35层
                <br/>收集个人信息的方式：SDK 自动收集
                <br/>收集的信息：无
                <br/>第三方隐私政策链接：<a href="https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl" className="protocol-link" target="_blank" rel="noreferrer">https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl</a>
              </p>
              <p className="protocol-section">支付宝支付：
                <br/>SDK 的来源及联系方式：支付宝（中国）网络技术有限公司，中国(上海)自由贸易试验区南泉北路447号15层
                <br/>收集个人信息的方式：SDK 自动收集
                <br/>收集的信息：手机 WIFI 信息、获取正在运行的应用列表、获取手机 ID 信息、获取 MAC 地址
                <br/>第三方隐私政策链接：<a href="https://docs.open.alipay.com/54" className="protocol-link" target="_blank" rel="noreferrer">https://docs.open.alipay.com/54</a>
              </p>
              <p className="protocol-section">如您使用 Apple iTunes 进行支付，Apple 还需收集您的 Apple ID 完成订单支付。</p>
              <p className="protocol-paragraph">六、为保障支付安全、进行正常结算及提供更好的产品服务，北京文睿网络科技有限公司将收集、记录您充值购买虚拟币并在本平台相关功能产品/服务中使用该虚拟币所产生的数据，包括但不限于充值、购买虚拟会员等产生的行为信息、交易记录（包括交易内容、交易状态、支付方式、金额、交易时间、交易单号）和账号余额等信息。</p>
              {/* <p className="protocol-paragraph">七、在 APP 内使用北京文睿网络科技有限公司的付费项目，如果授权人要求退款且北京文睿网络科技有限公司同意退款，退款会返回支付时所使用的第三方支付平台账户。</p> */}
              <p className="protocol-paragraph">八、授权人同意其消费产生的结算费用以北京文睿网络科技有限公司记录的数据为准，北京文睿网络科技有限公司将通过APP告知授权人。授权人对结算费用有异议，请通过以下方式联系：</p>
              <p>QQ：483374383</p>
              <p>联系邮箱：contact@bjwenrui.com&nbsp;</p>
            </div>
        )
    }
}
