import React, { Component } from 'react'

export default class products extends Component {
    render() { 
        return (
            <section className="latest-project sec-padd">
                <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="section-title">
                        <h2>产品展示</h2>
                    </div>
                    <div className="text">
                        <p>
                            北京文睿网络科技有限公司专业提供互联网软件定制研发，倾向于教育产品打造，助力教育服务供给模式升级和教育治理水平提升，发展基于互联网的教育服务新模式。
                        </p>
                    </div>
                    {/* <div className="link"> <a href="/439/a/anni/" className="default_link">更多产品 <i className="fa fa-angle-right"></i></a> </div> */}
                    </div>
                    <div className="col-md-9 col-sm-12 col-xs-12">
                    <div className="latest-project-carousel owl-carousel owl-theme"> 
                    <div className="item">
                        <div className="single-latest-project-carousel">
                            <div className="img-box"> <img src="/images/anli/cdc.png" alt="在线培训考评平台" style={{height:"220px"}}/>
                            <div className="overlay">
                                <div className="box">
                                <div className="content">
                                    <div className="top"> <a href="https://labtraining.chinacdc.cn/" target="_blank" rel="noopener noreferrer"><i className="fa fa-link"></i></a> </div>
                                    {/* <!-- /.top --> */}
                                    <div className="bottom">
                                    <div className="title center">
                                        <h3>在线培训考评平台</h3>
                                        <span>在线组织培训，课程视频学习、考试、颁发证书等</span> </div>
                                    </div>
                                </div>
                                {/* <!-- /.content -->  */}
                                </div>
                                {/* <!-- /.box -->  */}
                            </div>
                            {/* <!-- /.overlay -->  */}
                            </div>
                            {/* <!-- /.img-box -->  */}
                        </div>
                        </div>
                    <div className="item">
                        <div className="single-latest-project-carousel">
                            <div className="img-box"> <img src="/images/anli/zxkt.png" alt="在线课堂" style={{height:"220px"}}/>
                            <div className="overlay">
                                <div className="box">
                                <div className="content">
                                    <div className="top"> <a href="https://mc.bjwenrui.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-link"></i></a> </div>
                                    {/* <!-- /.top --> */}
                                    <div className="bottom">
                                    <div className="title center">
                                        <h3>在线课堂</h3>
                                        <span>在线直播、音视频互动、聊天等</span> </div>
                                    </div>
                                </div>
                                {/* <!-- /.content -->  */}
                                </div>
                                {/* <!-- /.box -->  */}
                            </div>
                            {/* <!-- /.overlay -->  */}
                            </div>
                            {/* <!-- /.img-box -->  */}
                        </div>
                        </div>
            <div className="item">
                        <div className="single-latest-project-carousel">
                            <div className="img-box"> <img src="/images/anli/zxxxpt.png" alt="一体化在线教学平台" style={{height:"220px"}}/>
                            <div className="overlay">
                                <div className="box">
                                <div className="content">
                                    <div className="top"> <a href="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-link"></i></a> </div>
                                    {/* <!-- /.top --> */}
                                    <div className="bottom">
                                    <div className="title center">
                                        <h3>一体化在线教学平台</h3>
                                        <span>适用于高校的全面的在线教学平台</span> </div>
                                    </div>
                                </div>
                                {/* <!-- /.content -->  */}
                                </div>
                                {/* <!-- /.box -->  */}
                            </div>
                            {/* <!-- /.overlay -->  */}
                            </div>
                            {/* <!-- /.img-box -->  */}
                        </div>
                        </div>
            <div className="item">
                        <div className="single-latest-project-carousel">
                            <div className="img-box"> <img src="/images/anli/zxks.png" alt="在线题库系统" style={{height:"220px"}}/>
                            <div className="overlay">
                                <div className="box">
                                <div className="content">
                                    <div className="top"> <a href="#!"><i className="fa fa-link"></i></a> </div>
                                    {/* <!-- /.top --> */}
                                    <div className="bottom">
                                    <div className="title center">
                                        <h3>在线题库系统</h3>
                                        <span>在线出卷、考试、阅卷，多端支持</span> </div>
                                    </div>
                                </div>
                                {/* <!-- /.content -->  */}
                                </div>
                                {/* <!-- /.box -->  */}
                            </div>
                            {/* <!-- /.overlay -->  */}
                            </div>
                            {/* <!-- /.img-box -->  */}
                        </div>
                        </div>
            <div className="item">
                        <div className="single-latest-project-carousel">
                            <div className="img-box"> <img src="/images/anli/peixun.png" alt="在线培训管理平台" style={{height:"220px"}}/>
                            <div className="overlay">
                                <div className="box">
                                <div className="content">
                                    <div className="top"> <a href="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-link"></i></a> </div>
                                    {/* <!-- /.top --> */}
                                    <div className="bottom">
                                    <div className="title center">
                                        <h3>在线培训管理平台</h3>
                                        <span>含招生缴费、教学、领证等于一体</span> </div>
                                    </div>
                                </div>
                                {/* <!-- /.content -->  */}
                                </div>
                                {/* <!-- /.box -->  */}
                            </div>
                            {/* <!-- /.overlay -->  */}
                            </div>
                            {/* <!-- /.img-box -->  */}
                        </div>
                        </div>
            <div className="item">
                        <div className="single-latest-project-carousel">
                            <div className="img-box"> <img src="/images/anli/wl.png" alt="物料管理系统" style={{height:"220px"}}/>
                            <div className="overlay">
                                <div className="box">
                                <div className="content">
                                    <div className="top"> <a href="#!"><i className="fa fa-link"></i></a> </div>
                                    {/* <!-- /.top --> */}
                                    <div className="bottom">
                                    <div className="title center">
                                        <h3>物料管理系统</h3>
                                        <span>设计计划、采购入库、下料加工、使用</span> </div>
                                    </div>
                                </div>
                                {/* <!-- /.content -->  */}
                                </div>
                                {/* <!-- /.box -->  */}
                            </div>
                            {/* <!-- /.overlay -->  */}
                            </div>
                            {/* <!-- /.img-box -->  */}
                        </div>
                        </div>
            
            </div>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}
