import React, { Component } from 'react'

export default class banner extends Component {
    render() {
        return (            
            <section className="rev_slider_wrapper">
                {/* <!--Start rev slider wrapper--> */}
              <div id="slider1" className="rev_slider"  data-version="5.0">
                <ul>
                  <li data-transition="fade"> <img src="/images/1.jpg"  alt="" width="1920" height="550" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />
                    <div className="tp-caption  tp-resizeme" 
                              data-x="left" data-hoffset="15" 
                              data-y="top" data-voffset="125" 
                              data-transform_idle="o:1;"         
                              data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" 
                              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" 
                              data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" 
                              data-splitin="none" 
                              data-splitout="none"
                              data-responsive_offset="on"
                              data-start="700">
                      <div className="slide-content-box">
                        {/* <h1>百度云作品<br/>
                          建站资源共享平台</h1>
                        <p>百度云专业提供网站模板，网页模板，教程培训，程序插件，<br/>
                          网站素材等建站资源 </p> */}
                      </div>
                    </div>
                    <div className="tp-caption tp-resizeme" 
                              data-x="left" data-hoffset="15" 
                              data-y="top" data-voffset="364" 
                              data-transform_idle="o:1;"                         
                              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"                     
                              data-splitin="none" 
                              data-splitout="none" 
                              data-responsive_offset="on"
                              data-start="2300">
                      <div className="slide-content-box">
                        {/* <div className="button"> <a className="thm-btn yellow-bg" href="http://www.baidu.com/">官方网站</a> </div> */}
                      </div>
                    </div>
                    <div className="tp-caption tp-resizeme" 
                              data-x="left" data-hoffset="158" 
                              data-y="top" data-voffset="364" 
                              data-transform_idle="o:1;"                         
                              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"                     
                              data-splitin="none" 
                              data-splitout="none" 
                              data-responsive_offset="on"
                              data-start="2600">
                      <div className="slide-content-box">
                        {/* <div className="button"> <a className="thm-btn our-solution" href="/">模板下载</a> </div> */}
                      </div>
                    </div>
                  </li>
                  <li data-transition="fade"> 
                    <img src="/images/2.jpg"  alt="" width="1920" height="580" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />
                    <div className="tp-caption tp-resizeme"
                              data-x="right" data-hoffset="15" 
                              data-y="center" data-voffset="-4" 
                              data-transform_idle="o:1;"         
                              data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" 
                              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" 
                              data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" 
                              data-splitin="none" 
                              data-splitout="none"  
                              data-responsive_offset="on"
                              data-start="500">
                      <div className="slide-content-box">
                        {/* <h1>baidu.com<br/>
                          建站资源共享平台</h1>
                        <p>百度云专业提供网站模板，网页模板，教程培训，程序插件，<br/>
                          网站素材等建站资源 </p>
                        <div className="button"> <a className="thm-btn yellow-bg" href="/">视频教程</a> <a className="thm-btn our-solution" href="/">在线培训</a> </div> */}
                      </div>
                    </div>
                  </li>
                  <li data-transition="fade"> <img src="/images/3.jpg"  alt="" width="1920" height="580" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />
                    <div className="tp-caption  tp-resizeme" 
                              data-x="left" data-hoffset="15" 
                              data-y="top" data-voffset="125" 
                              data-transform_idle="o:1;"         
                              data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" 
                              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" 
                              data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" 
                              data-splitin="none" 
                              data-splitout="none"
                              data-responsive_offset="on"
                              data-start="700">
                      {/* <div className="slide-content-box">
                        <h1>百度云官网 <br/>
                          建站资源共享平台</h1>
                        <p>百度云专业提供网站模板，网页模板，教程培训，程序插件，<br/>
                          网站素材等建站资源 </p>
                      </div> */}
                    </div>
                    <div className="tp-caption tp-resizeme" 
                              data-x="left" data-hoffset="15" 
                              data-y="top" data-voffset="364" 
                              data-transform_idle="o:1;"                         
                              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"                     
                              data-splitin="none" 
                              data-splitout="none" 
                              data-responsive_offset="on"
                              data-start="2300">
                      <div className="slide-content-box">
                        <div className="button"> <a className="thm-btn yellow-bg" href="/about">关于我们</a> </div>
                      </div>
                    </div>
                    <div className="tp-caption tp-resizeme" 
                              data-x="left" data-hoffset="188" 
                              data-y="top" data-voffset="364" 
                              data-transform_idle="o:1;"                         
                              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"                     
                              data-splitin="none" 
                              data-splitout="none" 
                              data-responsive_offset="on"
                              data-start="2600">
                      <div className="slide-content-box">
                        <div className="button"> <a className="thm-btn our-solution" href="/about">联系我们</a> </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* <!--End rev slider wrapper--> */}
            </section>
            
        )
    }
}
