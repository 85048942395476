import React, { Component } from 'react'

export default class Menu extends Component {
    render() {
        return (
            <section className="theme_menu stricky">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                                <div className="main-logo"> 
                                    <a href="/">
                                        <img src="/images/logo-wr.png" alt=""/>
                                    </a> 
                                </div>
                        </div>
                        <div className="right-column col-md-9 menu-column">
                            <nav className="menuzord" id="main_menu">
                                <ul className="menuzord-menu">
                                    <li><a href="/">网站首页</a></li>
                                    
                                    <li><a href="/ruitiku.shegong">社工锐题库</a></li>

                                    <li><a href="/about">关于我们</a></li>
                                    
                                    {/* 
                                    <li><a href="/anli">案例产品</a></li>
                                    <li><a href="/news">新闻中心</a></li>
                                    
                                    <li><a href="/contact">联系我们</a></li>
                                    
                                    <li><a href="http://www.baidu.com/" rel="noreferrer">百度</a></li> */}
                                </ul>
                                {/* End of .menuzord-menu */}
                            </nav>
                            {/* <!-- End of #main_menu -->  */}
                        </div>
                        {/* <div className="right-column">
                            <div className="right-area">
                                <div className="link_btn float_right"> 
                                    <a href="/439/a/lianxiwomen/" className="thm-btn">在线咨询</a> 
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            {/* <!-- End of .conatiner -->  */}
            </section> 
        )
    }
}
