import React, { Component } from 'react'

export default class Desc extends Component {
    render() {
        return ( 
            <section className="about-faq sec-padd">
                <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="about-info">
                        <h4>我们专注提供软件定制开发服务</h4>
                        <br/>
                        <div className="text">
                        <p>致力于为企业提供定制化互联网解决方案，APP开发、功能网站策划开发、业务系统开发、软件外包等服务。</p>
                        <br/>
                        <p>致力于为各行业企业提供微信定制服务，主要包括微商城、微营销、微社交、微网站等服务内容。</p>
                        <br/>
                        <p>帮助高校建设智慧校园一体化平台，助力教育服务供给模式升级和教育治理水平提升，发展基于互联网的教育服务新模式！</p>
                        <br/>
                        </div>
                        {/* <div className="link_btn"> <a href="/" className="thm-btn">了解更多</a>
                        <div className="sign"><img src="/images/sign.jpg" alt=""/></div>
                        </div> */}
                    </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="img-box"> <img src="/images/7.jpg" alt=""/> </div>
                        <br/>
                        <h4>网站定制</h4>
                        <br/>
                        <p>个人、商业、企业、娱乐、行业、社区、门户......</p>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="img-box"> <img src="/images/8.jpg" alt=""/> </div>
                        <br/>
                        <h4>移动应用定制</h4>
                        <br/>
                        <p>8亿微信用户新路径</p>
                        <p>体验设计+技术开发</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}
