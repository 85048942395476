import React, { Component } from 'react'
// import Header from '../Common/Header'
import Menu from '../Common/Menu'
import Footer from '../Common/Footer'
import Banner from './banner'
import Service from './service'
// import Product from './product'
import About from './about'
// import Count from './count'
import Products from './products'
import Metta from '../Common/Metta'
// import Customer from './customer'
export default class Index extends Component {
    render() {
        return (
            <>
                {/* <Metta></Metta> */}
                <div className="boxed_wrapper"> 
                    {/* <Header></Header> */}
                    <Menu></Menu>
                    <Banner></Banner>
                    <Service></Service>
                    {/* <Product></Product> */}
                    <About></About>
                    <div className="container">
                        <div className="border-bottom"></div>
                    </div>
                    {/* <Count></Count> */}
                    <Products></Products>
                    <div className="container">
                        <div className="border-bottom"></div>
                    </div>
                    {/* <Customer></Customer> */}
                    <Footer></Footer>
                    {/* <!-- Scroll Top Button --> */}
                    <button className="scroll-top tran3s color2_bg"> <span className="fa fa-angle-up"></span> </button>
                    {/* <!-- pre loader  --> */}
                    <div className="preloader"></div>
                </div>
            </>
        )
    }
}
