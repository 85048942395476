import React, { Component } from 'react'
import './index.css';
export default class PrivateAgreement extends Component {
    render() {
        return (
          <div className="register">
            {/* <div className='title'>隐私条款</div> */}
              {/* <p>
                <br/>
              </p> */}
              <p>第1版本；更新日期：2023年01月05日</p>
              <p>生效日期：2023年01月05日</p>
              <p>前言<br/></p>
              <p>北京文睿网络科技有限公司旗下APP及小程序（“旗下APP及小程序”包括社工锐题库、睿题库微信小程序、锐题库微信小程序，以下统称“北京文睿网络科技有限公司”或“我们”）非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《隐私条款》（“本隐私条款”）向您说明我们在您使用我们的产品、服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。我们亦将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。</p>
              <p>本《隐私条款》适用于北京文睿网络科技有限公司提供的产品或服务。对于未设独立隐私政策的北京文睿网络科技有限公司产品或服务，本《隐私条款》同样适用于该部分产品或服务。(截至目前我们运营的APP及小程序包括但不限于：社工锐题库、睿题库微信小程序、锐题库微信小程序等)</p>
              <p>请注意我们可能会不时地检查我们的条款，因此有关的措施亦可能随时会发生变化。您使用或在我们更新本隐私条款后（我们会及时提示您更新的情况）继续使用我们的产品与、服务，即意味着您同意本隐私条款(含更新版本)内容，并且同意我们按照本隐私条款收集、使用、保存您的相关信息。在阅读完本条款之后，如您对本《隐私条款》或与本《隐私条款》相关的事宜有任何问题，请通过contact@bjwenrui.com与我们联系。</p>
              <p>本条款将帮助您了解以下内容：</p>
              <p>我们可能收集哪些信息</p>
              <p>我们如何收集和使用信息</p>
              <p>我们可能共享、转让或披露的信息</p>
              <p>我们如何保留、储存和保护信息</p>
              <p>您如何管理个人信息</p>
              <p>未成年人信息的处理和保护</p>
              <p>通知和修订</p>
              <p>怎样联系我们</p>
              <p>&nbsp;</p>
              <p>一、我们可能收集哪些信息(收集信息的范围)</p>
              <p>（一）与个人身份无关的信息：</p>
              <p>当您使用北京文睿网络科技有限公司服务时，我们可能收集和汇总诸如用户的来源途径、访问顺序等信息，例如记录使用北京文睿网络科技有限公司服务的每个用户的来源途径、浏览器软件、设备相关的信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符SIM卡及ICCID信息、硬件序列号、设备环境等软硬件特征信息），以及设备所在位置相关信息（例如IP&nbsp;地址）等。</p>
              <p>（二）个人信息：</p>
              <p>&nbsp; 1、账户/号基本信息</p>
              <p>&nbsp; 当您使用北京文睿网络科技有限公司服务时，我们可能收集和汇总或要求您提供有关个人的信息，例如个人电话号码等；</p>
              <p>&nbsp; 2、网络身份标识信息</p>
              <p>&nbsp; 包括系统账号、IP地址及与前述有关的密码、短信验证码等。</p>
              {/* <p>&nbsp; 3、交易相关信息</p>
              <p>&nbsp; 当您决定在北京文睿网络科技有限公司下单时，您至少需要在订单中填写收货人姓名、收货地址、联系电话，同时订单中会载明订单号、您所购买的商品信息、下单时间、付款金额信息，如果您需要开具发票，还需提供发票信息；您在北京文睿网络科技有限公司下单后，您可以选择北京文睿网络科技有限公司合作的第三方支付机构（如微信支付、支付宝支付通道）所提供的支付服务，支付功能本身并不收集您的个人信息，但我们需要将您的订单信息及对账信息与这些支付机构共享以确认您的支付指令并完成支付。</p> */}
              <p>&nbsp; 3、交易相关信息</p>
              <p>&nbsp; 您在北京文睿网络科技有限公司下单后，您可以选择北京文睿网络科技有限公司合作的第三方支付机构（如微信支付、支付宝支付、苹果内购支付通道）所提供的支付服务，支付功能本身并不收集您的个人信息，但我们需要将您的订单信息及对账信息与这些支付机构共享以确认您的支付指令并完成支付。</p>
              <p>&nbsp;&nbsp;4、客服服务相关的通信记录等信息</p>
              <p>&nbsp; 当您与我们的客服取得联系时，我们的系统会记录您与客服之间的通讯记录，以及使用您的帐号信息以便核验身份；当您需要我们提供与您订单相关的客户服务时，我们可能会查询您的相关订单信息以便给予您适当的帮助和处理；当您需要客服协助您修改有关信息（如联系方式）时，您可能还需要提供上述信息外的其他信息以便完成修改。</p>
              <p>&nbsp;&nbsp;5、通讯信息</p>
              <p>&nbsp; 当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对北京文睿网络科技有限公司服务的详细使用情况，作为有关网络日志保存（例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间、您访问的网页记录等信息）。</p>
              <p>&nbsp; 我们收集您的信息主要是为了您和其他用户能够更好地使用和体验北京文睿网络科技有限公司服务，而这些信息有助于我们更好的提升服务水平和您的用户体验。</p>
              <p>&nbsp; 请注意，若您在使用北京文睿网络科技有限公司服务过程中在其他用户可见的公开区域内上传或发布的信息中、您对其他人上传或发布的信息作出的回应中公开您的个人信息的，则该等信息可能会被他人收集并加以使用。当您在使用北京文睿网络科技有限公司服务过程中发现自身或他人的个人信息被不正当地收集或使用时，请联系contact@bjwenrui.com.&nbsp;</p>
              <p>二、我们如何收集和使用信息</p>
              <p>（一）我们将通过以下途径收集和获得您的信息(收集信息的途径)：</p>
              <p>1、您提供的信息。例如：</p>
              <p>（1）您在注册北京文睿网络科技有限公司各项产品的账号或使用北京文睿网络科技有限公司任一服务时，向我们提供的信息；</p>
              <p>（2）您通过北京文睿网络科技有限公司产品或服务向其他方提供的共享信息，以及您使用服务时所储存的信息。</p>
              <p>2、其他方分享的您的信息。亦即其他方使用北京文睿网络科技有限公司服务时所提供的涉及您的共享性信息。</p>
              <p>3、我们获取的您的信息。您在使用北京文睿网络科技有限公司产品或服务时，我们收集、汇总、记录的相关信息，例如日志信息、设备信息等。</p>
              <p>（二）我们会出于如下目的去收集和使用您的信息(收集信息的目的及具体方式)：</p>
              <p>1、帮助您完成注册</p>
              <p>为便于我们为您提供服务，您需要提供基本注册信息，例如手机号码等，并创建您的用户名和密码。在部分单项服务中，如果您仅需使用浏览、以游客身份或者进行匿名体验等基本服务，您不需要注册成为北京文睿网络科技有限公司用户及提供上述信息。</p>
              <p>2、向您提供必要的服务</p>
              <p>我们所收集和使用的信息是为您提供服务的必要条件，如缺少相关信息，我们将无法为您提供有关服务的核心内容，例如：</p>
              {/* <p>（1）在部分服务项目中，为便于向您交付教材或其他相关文件资料，您需提供收货人姓名、收货地址、邮政编码、联系电话等信息。如果您拒绝提供此类信息，则我们将无法完成相关交付服务。如您是为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您务必确保您已经取得其授权同意。</p> */}
              {/* <p>（2）为展示您账户的订单信息，我们会收集您在使用我们服务过程中产生的订单信息用以向您展示并便于您对订单的管理；</p> */}
              <p>（1）当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
              <p>（2）为方便您使用北京文睿网络科技有限公司提供的服务，我们可能会将您在账户注册和使用北京文睿网络科技有限公司服务过程中提供、形成、发布的信息在北京文睿网络科技有限公司内部进行共享和传递。</p>
              <p>（3）为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。</p>
              <p>3、向您推送消息</p>
              {/* <p>（1）为您展示和推送产品或服务：我们可能使用您的浏览及搜索记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，并基于特征标签通过电子邮件、短信或其他方式向您发送或邮寄营销信息，提供或推广我们或第三方的相关产品和服务。</p> */}
              <p>（1）向您发出通知：我们可能在必需时（例如当我们由于系统维护或业务调整而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与您有关的通知。</p>
              {/* <p>如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如：根据短信退订指引要求我们停止发送短信，或在移动端设备中进行设置、不再接收我们推送的消息等。但我们依法律规定或单项服务的服务协议约定发送消息的情形除外。</p> */}
              <p>如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如：根据短信退订指引要求我们停止发送短信等。但我们依法律规定或单项服务的服务协议约定发送消息的情形除外。</p>
              <p>4、为您提供安全保障</p>
              {/* <p>为确保您身份真实性、向您提供更好的安全保障，您可以向我们提供身份证明、面部特征等生物识别信息等个人敏感信息以完成实名认(验)证。</p> */}
              {/* <p>除身份验证外，我们可能将您的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途，确保我们向您提供的服务的安全性；我们可能使用或整合我们所收集的您的信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p> */}
              <p>我们可能将您的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途，确保我们向您提供的服务的安全性；我们可能使用或整合我们所收集的您的信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p>
              <p>5、改进我们的服务</p>
              <p>我们可能将通过某一项服务所收集的信息，用于我们的其他服务。</p>
              <p>您了解并同意，在收集您的信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并做商业化利用。</p>
              <p>如果我们将您的信息用于本《隐私条款》中未载明的其他用途，会事先征求您同意。</p>
              <p>6、向您提供更加便捷的服务</p>
              <p>为向您提供更便捷、更优质、个性化的产品和服务，努力提升您的体验，在我们向您提供的某些服务中可能会通过一些附加功能、项目去收集和使用您的个人信息。如果您不提供这些信息，不会影响您使用我们产品和服务的基本功能，但您可能亦无法获得由这些附加功能、项目给您带来的更加优质和个性化的用户体验。这些附加功能包括：</p>
              <p>（1）基于位置信息进行服务优化：我们会在您开启位置权限后访问获取您的位置信息，并根据您的位置信息提供更具针对性、更优化的服务体验，例如用于为你提供当前地区考试相关信息等；</p>
              {/* <p>（2）基于摄像头（相机）的功能：您可以使用这个功能完成拍摄照片或视频用于评价、分享、提问互动，以及特定场景下经您授权的通过人脸识别进行实名验证登陆等功能。当您使用人脸识别登录时我们会收集您的面部信息。未来我们亦可能将人脸识别技术应用于更多服务场景，届时我们会再次与您确认您是否愿意我们使用您的面部信息、并严格在经您授权同意的范围内使用；</p> */}
              {/* <p>（3）基于相册（图片库/视频库）的图片/视频访问及上传的功能：您可在开启相册权限后使用该功能上传您的照片/图片/视频，以实现更换头像、发表评论、分享经验感受、与老师或客服沟通时提供更加直观和便捷的书面信息等功能。我们可能会通过您所上传的相片/图片/视频内容来识别您具体需要我们提供的服务或帮助，或使用包含您所上传相片/图片/视频内容的其他附随信息；</p> */}
              <p>（2）基于相册（图片库）的图片，上传及图片下载的功能：您可在开启相册权限后使用该功能上传您的照片/图片，或下载图片至相册，以实现更换头像、保存专属老师二维码等功能。我们可能会通过您所上传的相片/图片内容来识别您具体需要我们提供的服务或帮助，或使用包含您所上传相片/图片内容的其他附随信息；</p>
              <p>（3）基于麦克风的语音技术相关附加功能：您可在开启麦克风权限后使用麦克风实现通知消息播报、以及需要我们提供的服务和帮助；</p>
              {/* <p>（5）基于通讯录信息的扩展功能：我们可能会在您开启通讯录权限后、并在获得您同意的前提下，通过识别您通讯录中的联系人是否也同为北京文睿网络科技有限公司用户、在北京文睿网络科技有限公司为你们建立联系、交流的纽带，增添您学习过程中的社交乐趣；</p> */}
              <p>（4）文件存储：包括存储应用资源文件，下载文件本地读取等；</p>
              {/* <p>（7）信息推送：在您开启推送权限后将更为方便收取我们发送给您的通知消息；</p> */}
              {/* <p>（8）日历：在您开启我们可读取/写入您日历的权限后，我们将收集您的日历信息用于记录课程日期服务；</p> */}
              <p>（5）授权登陆：即经您授权同意后从第三方处获取您的第三方账户信息(头像、昵称及其他页面提示的信息)，以使您可通过第三方账户直接登录、使用我们的产品及服务；</p>
              {/* <p>（10）读取短信：经您授权同意后读取您的手机短信并用于您授权许可的用途（如通过手机号码从第三方平台完成对我们产品及服务的登陆和获取）；</p> */}
              {/* <p>（11）全局弹框：通过该权限您在直播观看过程中若返回到主界面时也仍然可以继续通过浮窗观看直播内容；</p> */}
              {/* <p>（12）获取常用设备信息：我们可能会通过收集您的手机号码、IMEI、IMSI等设备基本信息来确定和优化为您提供的产品、服务等信息的最优展示方式。我们也会为了不断改进和优化前述功能来使用此类信息。</p> */}
              <p>&nbsp; 您理解并同意，上述附加功能可能需要您在您的设备中开启相册（图片库）的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启上述任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
              {/* <p>&nbsp; 您理解并同意，上述附加功能可能需要您在您的设备中开启您的位置信息&nbsp;（地理位置）、摄像头（相机）、相册（图片库）、麦克风（语音）、通讯录的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启上述任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。除此以外，我们在相关业务功能中可能还需要您开启设备的其他访问权限，详细权限和使用目的如下：</p> */}
              {/* <p>（1）android.permission.INTERNET（访问网络权限）：实现应用程序联网
                <br/>（2）android.permission.ACCESS_WIFI_STATE（获取WiFi状态权限）：监控网络变化，提示用户当前网络环境
                <br/>（3）android.permission.ACCESS_NETWORK_STATE（获取网络状态权限）：监控网络变化，提示用户当前网络环境
                <br/>（4）android.permission.VIBRATE（使用振动权限）：允许手机振动
                <br/>（5）android.permission.WAKE_LOCK（唤醒锁定权限）：允许程序在手机屏幕关闭后后台进程仍然运行，保持屏幕唤醒
                <br/>（6）android.permission.MODIFY_AUDIO_SETTINGS（修改声音设置权限）：修改全局音频设置，例如调节音量和用于输出的扬声器
                <br/>（7）android.permission.BLUETOOTH（使用蓝牙权限）：直播中使用蓝牙耳机
                <br/>（8）android.permission.BLUETOOTH_ADMIN（蓝牙管理权限）：直播中使用蓝牙耳机
                <br/>（9）android.permission.SYSTEM_ALERT_WINDOW（悬浮窗权限）：观看直播悬浮窗播放&nbsp;
                <br/>（10）android.permission.ACCESS_BACKGROUND_LOCATION（支持后台访问位置权限）：保持持续定位能力&nbsp;
                <br/>（11）android.permission.CHANGE_WIFI_STATE（改变WiFi状态权限）：允许应用改变WiFi状态
                <br/>（12）android.permission.ACCESS_FINE_LOCATION（获取定位权限）：需要获取您的位置信息用于“求职”课精准推荐就业信息（对啊课堂），用于个性化推荐、商品购买填写收货地址（随身学）
                <br/>（13）android.permission.RECEIVE_BOOT_COMPLETED（获取启动完成通知）：获取手机的启动状态，用于启动推送以及课程通知服务
                <br/>（14）android.permission.REQUEST_INSTALL_PACKAGES（获取安装的程序列表）：用于跳转到本应用的权限设置页面</p> */}
              <p>7、征得授权同意的例外</p>
              <p>根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：</p>
              <p>（1）与国家安全、国防安全有关的；</p>
              <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
              <p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
              <p>（4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
              <p>（5）所收集的信息是您自行向社会公众公开的；</p>
              <p>（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；</p>
              <p>（7）根据您的要求签订合同所必需的；</p>
              <p>（8）用于维护北京文睿网络科技有限公司服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
              <p>（9）为合法的新闻报道所必需的；&nbsp;</p>
              <p>（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；</p>
              <p>（11）法律法规规定的其他情形。</p>
              <p>（三）Cookie和同类技术的应用</p>
              <p>1、&nbsp;Cookie&nbsp;</p>
              <p>&nbsp; 为确保产品及服务的正常运转、为您获得更轻松的访问体验、向您推荐更具针对性的内容，我们会在您的计算机或移动设备上存储Cookie、Flash Cookie，或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助于&nbsp;Cookie，我们能更好、更快地为您服务，并且使您在体验北京文睿网络科技有限公司产品和服务过程中的经历更富个性化。</p>
              <p>2、&nbsp;Cookie&nbsp;同类技术</p>
              <p>&nbsp; 除&nbsp;Cookie外，我们还会在网站或产品上使用网络Beacon、像素标签等其他同类技术，用以记住您的身份、对您使用我们产品或服务的情况进行统计分析等。</p>
              <p>您可以通过浏览器或用户选择机制拒绝或管理&nbsp;Cookie或网络Beacon。但请您注意，如果您停用&nbsp;Cookie或网络Beacon，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。</p>
              {/* <p>3、SDK的使用</p>
              <p>功能使用说明:（需要新加入的，这些也是无直接合理关联的业务功能）</p> */}
              {/* <p>Intent.ACTION_NEW_OUTGOING_CALL&nbsp; 电话呼出&nbsp; &nbsp;直播上麦过程中监听电话呼出进行音频切换用的</p>
              <p>剪切板：课件分享，地址复制到剪切板（微信公众号复制到剪切板）该读取不是采集信息，不会持久保存；</p>
              <p>shareSDK中（当终端用户需要进行口令分享时，ShareSDK将读取终端用户的剪切板信息，该读取不是采集信息，不会持久保存；）</p>
              <p>
                <br/>
              </p> */}
              {/* <p>为保障我们服务的稳定运行、功能实现，实现本隐私条款中声明的目的，我们可能会嵌入第三方服务商提供的SDK或其他类似的应用程序，并将我们依照本政策收集的您的某些信息共享给该等第三方服务商，以便提供更好的客户服务和用户体验。本平台使用的SDK包括：</p> */}
              {/* <p>1）极光推送</p>
              <p>功能：用于向用户推送公开课上课信息以及其他活动相关信息</p>
              <p>收集个人信息类型：设备信息[设备信息包括：设备标识符（IMEI、IDFA、Android&nbsp;ID(Android_ID)、MAC、OAID等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）]、网络信息[网络信息包括：IP地址，WiFi信息，基站信息等相关信息。]、地理位置信息、运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://www.jiguang.cn/license/privacy" _src="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a>&nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>2）Mobtech/sharesdk</p>
              <p>功能：帮助用户分享内容至第三方应用</p>
              <p>收集个人信息类型：系统信息；WIFI状态信息；应用列表信息；基站信息；地理位置</p>
              <p>隐私协议条款链接：
                <a href="http://www.mob.com/about/policy" _src="http://www.mob.com/about/policy">http://www.mob.com/about/policy</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>3）创蓝闪验SDK：上海璟梦信息科技有限公司
                <br/>功能：用于实现App一键登录和验证用户手机号码与本机流量卡号是否一致的服务
                <br/>收集个人信息的类型：设备信息、位置信息、网络信息、SDK认证相关请求结果、手机号码设备信息：设备标识符（IMEI、IDFA、Android ID、MAC、OAID等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）</p>
              <p>隐私协议条款链接：
                <a href="https://tensdk.com/commit-detail/rcc5zd/fd21s8" _src="https://tensdk.com/commit-detail/rcc5zd/fd21s8">https://tensdk.com/commit-detail/rcc5zd/fd21s8</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>4）极光认证</p>
              <p>功能：帮助用户分享内容至第三方应用</p>
              <p>收集个人信息类型：设备信息[设备信息包括：设备标识符（IMEI、IDFA、Android&nbsp;ID(Android_ID)、MAC、OAID等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）]、网络信息[网络信息包括：IP地址，WiFi信息，基站信息等相关信息。]、地理位置信息、运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://www.jiguang.cn/license/privacy" _src="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>5）FaceId</p>
              <p>功能：用户的账号下有系统班时，注册或登录账号时需使用人脸识别后进入APP</p>
              <p>收集个人信息类型：设备标识符</p>
              <p>隐私协议条款链接：
                <a href="https://list.duia.com/policy" _src="https://list.duia.com/policy">https://list.duia.com/policy</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>6）Umeng</p>
              <p>包名：com.uc.crashsdk、com.efs、com.umeng</p>
              <p>功能：用于分析用户使用情况，以及统计用户崩溃数据</p>
              <p>收集个人信息类型：设备Mac地址、唯一设备识别码（IMEI/android&nbsp;ID(Android_Id)/IDFA/OPENUDID/GUID、SIM卡IMSI信息）、运⾏中进程信息、设备序列号</p>
              <p>隐私协议条款链接：
                <a href="https://www.umeng.com/page/policy" _src="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>7）百度统计</p>
              <p>功能：用于分析用户使用情况，以及统计用户崩溃数据</p>
              <p>收集个人信息类型：设备属性信息（例如最终用户的硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码IMEI、SIM卡信息IMSI、iOS系统广告标识符（IDFA)、安卓系统广告标识符（OAID）、Android&nbsp;Id（Android_ID ）、设备Mac地址）、设备位置信息（通过GPS、蓝牙或Wi-Fi信号获得的位置信息）以及设备状态信息、运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://tongji.baidu.com/web/help/article?id=330&type=0" _src="https://tongji.baidu.com/web/help/article?id=330&type=0">https://tongji.baidu.com/web/help/article?id=330&amp;type=0</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>8）获得场景视频（原CC视频,VOD_Android_SDK）</p>
              <p>功能：用于观看直播、点播课程</p>
              <p>收集个人信息类型：设备标识符,运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="http://www.bokecc.com" _src="http://www.bokecc.com">http://www.bokecc.com</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>9）展示互动</p>
              <p>功能：用于观看直播、点播课程</p>
              <p>收集个人信息类型：设备标识符</p>
              <p>隐私协议条款链接：
                <a href="http://www.gensee.cc/net/index.htm" _src="http://www.gensee.cc/net/index.htm">http://www.gensee.cc/net/index.htm</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>10）听云</p>
              <p>功能：用于分析用户网络情况、用户的应用崩溃情况</p>
              <p>收集个人信息类型：唯一设备识别码（Andro&nbsp;id（Android_ID）系统IMEI/MAC地址/OAID），地理位置（经纬度），运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://www.tingyun.com/legal_declaration.html" _src="https://www.tingyun.com/legal_declaration.html">https://www.tingyun.com/legal_declaration.html</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>11）Bugly</p>
              <p>功能：分析用户的应用崩溃情况</p>
              <p>收集个人信息类型：日志信息(包括:第三方开发者自定义日志、Logcat日志以及APP崩溃堆栈信息)、设备ID(包括:android&nbsp;id（Android_ID）d以及idfv)、联网信息、系统名称、系统版本以及国家码</p>
              <p>隐私协议条款链接：
                <a href="https://bugly.qq.com/v2/contract" _src="https://bugly.qq.com/v2/contract">https://bugly.qq.com/v2/contract</a>&nbsp; &nbsp;
                <br/></p>
              <p>
                <br/>
              </p> */}
              {/* <p>12）支付宝</p>
              <p>功能：可帮助用户完成付款、提供安全认证服务</p>
              <p>收集个人信息类型：设备标识符、位置信息、运营商信息、Wi-Fi地址、应用列表</p>
              <p>隐私协议条款链接：
                <a href="https://render.alipay.com/p/c/k2cx0tg8" _src="https://render.alipay.com/p/c/k2cx0tg8">https://render.alipay.com/p/c/k2cx0tg8</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>13）微信支付</p>
              <p>功能：可帮助用户完成付款、提供安全认证服务</p>
              <p>收集个人信息类型：设备标识符、位置信息、运营商信息、Wi-Fi地址、应用列表</p>
              <p>隐私协议条款链接：
                <a href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml" _src="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml">https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>14）高德</p>
              <p>功能：帮助用户在发布信息时定位位置</p>
              <p>收集个人信息类型：Wi-Fi状态信息、定位信息、终端设备唯一标识符</p>
              <p>隐私协议条款链接：
                <a href="https://lbs.amap.com/home/privacy" _src="https://lbs.amap.com/home/privacy">https://lbs.amap.com/home/privacy</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>15）小能</p>
              <p>功能：客服工作平台，为用户提供客服服务</p>
              <p>收集个人信息类型：设备标识符、相册权限、麦克风权限，运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://www.xiaoneng.cn/index.html" _src="https://www.xiaoneng.cn/index.html">https://www.xiaoneng.cn/index.html</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>16）美洽</p>
              <p>功能：客服工作平台，为用户提供客服服务</p>
              <p>收集个人信息类型：硬件型号、操作系统版本号、国际移动设备识别码（IMEI）、网络设备硬件地址（MAC）等软硬件数据、运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://app.meiqia.com/static/static/agreeting/team-service-meiqia.html" _src="https://app.meiqia.com/static/static/agreeting/team-service-meiqia.html">https://app.meiqia.com/static/static/agreeting/team-service-meiqia.html</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>17）科大讯飞</p>
              <p>功能：用于收取用户声音，转换为文字，或者评测语音</p>
              <p>收集个人信息类型：唯一标识符Android_ID、IP地址、地理位置、运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://www.xfyun.cn/doc/policy/privacyagreement.html" _src="https://www.xfyun.cn/doc/policy/privacyagreement.html">https://www.xfyun.cn/doc/policy/privacyagreement.html</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>18）阿里百川</p>
              <p>功能：收集用户的意见反馈</p>
              <p>收集个人信息类型：设备相关信息（包括设备型号、操作系统版本、设备设置、Android_ID、MAC地址及IMEI、IDFA、OAID等设备标识符、设备环境、移动应用列表等软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）、运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao201703241622_61002.html?spm=a2145.7268393.0.0.f9aa5d7cIsQfdd" _src="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao201703241622_61002.html?spm=a2145.7268393.0.0.f9aa5d7cIsQfdd">https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao201703241622_61002.html?spm=a2145.7268393.0.0.f9aa5d7cIsQfdd</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>19）联通</p>
              <p>功能：中国联通认证服务协议
                <br/></p>
              <p>收集个人信息类型：网络类型、屏幕尺寸、IP地址、手机号码</p>
              <p>隐私协议条款链接：
                <a href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true" _src="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true">https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p>
              <p>20）CMIC</p>
              <p>功能：中国移动认证服务条款</p>
              <p>收集个人信息类型：手机设备类型、手机操作系统、硬件厂商、国际移动设备识别码、运营商类型、网络类型、sim卡数量、国际移动用户识别码、网络地址、屏幕尺寸、屏幕分辨率</p>
              <p>隐私协议条款链接：
                <a href="http://wap.cmpassport.com/resources/html/contract.html" _src="http://wap.cmpassport.com/resources/html/contract.html">http://wap.cmpassport.com/resources/html/contract.html</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p>
              <p>21）天翼</p>
              <p>功能：中国电信天翼账号服务条款</p>
              <p>收集个人信息类型：网络IP地址、网络类型、手机号码、Android_ID、运⾏中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://e.189.cn/sdk/agreement/detail.do" _src="https://e.189.cn/sdk/agreement/detail.do">https://e.189.cn/sdk/agreement/detail.do</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>22）微博</p>
              <p>功能：将页面分享至新浪微博</p>
              <p>收集个人信息类型：设备唯一标识</p>
              <p>隐私协议条款链接：
                <a href="https://m.weibo.cn/c/privacy" _src="https://m.weibo.cn/c/privacy">https://m.weibo.cn/c/privacy</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>23）腾讯QQ</p>
              <p>功能：登录、将页面分享至QQ好友或朋友圈</p>
              <p>收集个人信息类型：设备型号、操作系统、设备Mac地址、唯一设备标识符、应用ID、登陆IP地址、QQ软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息（如您在阅读功能下的浏览历史、服务故障信息等信息）等日志信息</p>
              <p>隐私协议条款链接：
                <a href="https://ti.qq.com/agreement/qqface.html?appname=mqq_2019" _src="https://ti.qq.com/agreement/qqface.html?appname=mqq_2019">https://ti.qq.com/agreement/qqface.html?appname=mqq_2019</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>24）小米推送</p>
              <p>第三方名称：小米科技有限责任公司</p>
              <p>功能：用于对啊课堂向小米手机用户推送消息</p>
              <p>收集个人信息类型：IMEI/OAID（适用于Android Q以上系统）、IMEI MD5/MEIDMD5、Android ID、VAID、AAID，以及您的MID（适用于MIUI系统）、设备型号、WiFi信息</p>
              <p>隐私权政策链接：
                <a href="https://dev.mi.com/console/doc/detail?pId=1822" _src="https://dev.mi.com/console/doc/detail?pId=1822">https://dev.mi.com/console/doc/detail?pId=1822</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>25）新浪微博开放平台</p>
              <p>第三方名称:北京微梦创科网络技术有限公司</p>
              <p>使用目的:登录及分享内容到新浪微博</p>
              <p>处理方式:通过去标识化、加密传输和处理的安全处理</p>
              <p>收集个人信息类型你:设备标识信息、网络信息</p>
              <p>隐私政策链接：
                <a href="http://open.weibo.com/" _src="http://open.weibo.com/">http://open.weibo.com/</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>26）腾讯浏览服务</p>
              <p>第三方名称：深圳市腾讯计算机系统有先公司</p>
              <p>功能：用于对啊课堂直播文档以X5内核提供浏览增强</p>
              <p>处理方式：通过去标识化、加密传输和处理的安全处理方式</p>
              <p>收集个人信息类型:IMEI、IMSI、Android_ID、直播文档加载通过手机设备信息用于错误日志搜集、运⾏中进程信息</p>
              <p>隐私政策:
                <a href="https://x5.tencent.com/tbs/product/tbs.html" _src="https://x5.tencent.com/tbs/product/tbs.html">https://x5.tencent.com/tbs/product/tbs.html</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>27）旷视人脸识别（Face++）</p>
              <p>第三方名称：北京旷视科技有限公司</p>
              <p>功能：用户的账号下有系统班时，注册或登录账号时需使用人脸识别后进入APP</p>
              <p>处理方式：通过去标识化、加密传输和处理的安全处理方式</p>
              <p>收集个人信息类型：设备标识符</p>
              <p>隐私协议条款链接：
                <a href="https://list.duia.com/policy" _src="https://list.duia.com/policy">https://list.duia.com/policy</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p>
              <p>28）声网（agora）</p>
              <p>第三方名称：声网有限公司</p>
              <p>功能：用于对啊课堂直播中上麦视频音频交互功能（为移动端到移动端以及移动端到 Web 端提供质量可靠的实时音视频通信）</p>
              <p>处理方式：通过去标识化、加密传输和处理的安全处理方式</p>
              <p>收集个人信息类型：设备标识信息、网络信息</p>
              <p>隐私协议条款链接:
                <a href="https://www.agora.io/cn/privacy-policy" _src="https://www.agora.io/cn/privacy-policy">https://www.agora.io/cn/privacy-policy</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>29）阿里云（oss）</p>
              <p>第三方名称：阿里云计算有限公司</p>
              <p>功能:用于对啊课堂日志上传的数据存储</p>
              <p>处理方式：通过去标识化、加密传输和处理的安全处理方式</p>
              <p>收集个人信息类型：设备标识信息&nbsp;Android_ID、网络信息、运⾏中进程信息</p>
              <p>隐私协议条款链接:
                <a href="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202107091605_49213.html?spm=a1zaa.8161610.0.0.244e4144JRJCYv" _src="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202107091605_49213.html?spm=a1zaa.8161610.0.0.244e4144JRJCYv">https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202107091605_49213.html?spm=a1zaa.8161610.0.0.244e4144JRJCYv</a>&nbsp;
                <br/></p>
              <p>
                <br/>
              </p>
              <p>30）腾讯实时音视频(TRTC)</p>
              <p>第三方名称: 腾讯科技（深圳）有限公司</p>
              <p>功能：用于对啊课堂直播音视频通话和低延时互动直播方案处理</p>
              <p>处理方式：通过去标识化、加密传输和处理的安全处理方式</p>
              <p>收集个人信息类型：设备标识信息、网络信息，</p>
              <p>电话状态(SDK 需要监听电话的打断，在电话呼入时，</p>
              <p>停止音频的采集（仅当终端用户的 Android 系统低于Android 6.0版本时需要申请）)；访问摄像头（用于采集摄像头画面，与其他使用者进行交互）</p>
              <p>隐私协议条款链接：
                <a href="https://privacy.qq.com/policy/apps-privacypolicy" _src="https://privacy.qq.com/policy/apps-privacypolicy">https://privacy.qq.com/policy/apps-privacypolicy</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>31）网易易盾SDK
                <br/>第三方名称:&nbsp;杭州网易易盾科技有限公司
                <br/>处理方式：访问联网、设备信息（设备识别码）、获取当前网络环境。</p>
              <p>收集用户信息/数据类型：设备标识信息，日志信息，应用信息。
                <br/>号码认证：https://dun.163.com/clause/privacy&nbsp;
                <br/>隐私协议条款链接：
                <a href="https://dun.163.com/clause/privacy" _src="https://dun.163.com/clause/privacy">https://dun.163.com/clause/privacy</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p>
              <p>32）RxLifecycle(个人开源框架)</p>
              <p>包名:com.trello</p>
              <p>功能：Android开发基础组件</p>
              <p>收集个人信息类型：运行中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://github.com/trello/RxLifecycle" _src="https://github.com/trello/RxLifecycle">https://github.com/trello/RxLifecycle</a>&nbsp; &nbsp;</p>
              <p>
                <br/>
              </p>
              <p>33）okhttp(square)</p>
              <p>包名:com.android.okhttp</p>
              <p>功能：网络库</p>
              <p>收集个人信息类型：运行中进程信息</p>
              <p>隐私协议条款链接：
                <a href="https://squareup.com/help/us/en/article/3796-privacy-and-security" _src="https://squareup.com/help/us/en/article/3796-privacy-and-security">https://squareup.com/help/us/en/article/3796-privacy-and-security</a>
              </p>
              <p>
                <br/>
              </p>
              <p>34）AndroidChromium</p>
              <p>包名:org.chromium</p>
              <p>功能: 网络服务</p>
              <p>收集个人信息类型 : 粘贴板信息</p>
              <p>隐私协议条款链接：
                <a href="https://github.com/JackyAndroid/AndroidChromium" _src="https://github.com/JackyAndroid/AndroidChromium">https://github.com/JackyAndroid/AndroidChromium</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>35）巨量引擎转化SDK
                <br/>包名:com.bytedance.applog
                <br/>功能: 字节推广转化统计与分析服务
                <br/>收集个人信息类型 : 【Android】设备品牌、型号、软件系统版本相关信息、oaid等基础信息
                <br/>隐私协议条款链接：
                <a href="https://event-manager.oceanengine.com/docs/8650/194615/" _src="https://event-manager.oceanengine.com/docs/8650/194615/">https://event-manager.oceanengine.com/docs/8650/194615/</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>36）魅族推送服务</p>
              <p>功能：用于向魅族手机用户推送公开课上课信息以及其他活动；</p>
              <p>采集的个人信息类型：主要包括设备名称、设备型号、地区和语言设置、设备识别码（IMEI 号码等）、设备硬件信息与状态、使用习惯、IP 地址、Mac 地址、操作系统版本，以及用于访问服务的设备的设置信息；</p>
              <p>隐私协议条款地址：
                <a href="http://static.meizu.com/resources/term/privacy8.html" _src="http://static.meizu.com/resources/term/privacy8.html">http://static.meizu.com/resources/term/privacy8.html</a>&nbsp;</p>
              <p>
                <br/>
              </p> */}
              {/* <p>37）OPPO推送服务</p>
              <p>功能：用于向OPPO手机用户推送公开课上课信息以及其他活动；</p>
              <p>收集个人信息类型：设备识别码（IMEI或OAID），IP地址，客户端系统、客户端网络类型、客户端时间等；</p>
              <p>隐私协议条款地址：
                <a href="https://open.oppomobile.com/new/developmentDoc/info?id=10288" _src="https://open.oppomobile.com/new/developmentDoc/info?id=10288">https://open.oppomobile.com/new/developmentDoc/info?id=10288</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>38）VIVO推送服务</p>
              <p>功能：用于向VIVO手机用户推送公开课上课信息以及其他活动；</p>
              <p>收集个人信息类型：设备标识信息（如IMEI、EmmCID、UFSID、ANDROIDID、GUID、GAID、OPENID、VAID、OAID、RegID、加密的Android ID）、使用推送服务的应用软件信息（如应用包名、版本号、APPID、安装、卸载、恢复出厂设置、运行状态）、设备制造商、网络类型、国家码、设备类型；</p>
              <p>隐私协议条款地址：
                <a href="https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822" _src="https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822">https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>39）华为推送服务</p>
              <p>功能：用于向华为手机用户推送公开课上课信息以及其他活动；</p>
              <p>收集个人信息类型：设备名称、设备标识符、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字号）等。</p>
              <p>隐私协议条款地址：
                <a href="https://consumer.huawei.com/cn/privacy/privacy-policy/" _src="https://consumer.huawei.com/cn/privacy/privacy-policy/">https://consumer.huawei.com/cn/privacy/privacy-policy/</a>&nbsp;</p>
              <p>
                <br/>
              </p>
              <p>我们接入第三方SDK旨在服务于您以及其他用户的需求，因此在为满足新的服务需求或业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在前述SDK详情页中向您公开说明接入第三方SDK的最新情况。
                <br/></p> */}
              <p>（四）我们从第三方获得您个人信息的情形</p>
              <p>我们可能根据您的授权从第三方获取您的第三方账户信息（头像、昵称、短信验证码及其他页面提示的信息），并在您同意本隐私条款后将您的第三方账户与您的北京文睿网络科技有限公司账户绑定，使您可以通过第三方账户直接登录并使用我们的产品和服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</p>
              <p>三、我们可能共享、转让或披露的信息</p>
              <p>（一）共享</p>
              <p>除以下情形外，未经您同意，我们不会与北京文睿网络科技有限公司之外的任何第三方共享您的信息：</p>
              {/* <p>1、向您提供我们的服务。我们可能向合作伙伴及其他第三方技术、服务供应商共享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息、向第三方技术、服务供应商提供其完成任务所必需范围及程度的相关信息（包括为完成实名认证所需要的身份证、护照等证件照片和信息，为完成登录真实性核验和账户安全监测所需要的人脸面部照片及动态视频等生物识别特征等）用于比对、识别服务。在接受我们服务的过程中，如您响应信息系统要求、按照系统提示提供前述各类个人信息的，即视为您已明确知悉并同意我们收集并向合作第三方提供、共享前述信息，亦同意接收信息的第三方合作伙伴取得该类信息并用于完成其基于合作义务所应履行的必要职责。</p> */}
              {/* <p>前述对您相关信息的提供和共享将严格限制在合法、正当、必要、特定、明确的目的范围内，我们的第三方授权合作伙伴只能接触到其履行职责所必需的信息，且不得将此信息用于其他任何目的或超越合作范围或合理程度。</p> */}
              {/* <p>2、维护和改善我们的服务。我们可能同合作伙伴及其他第三方共享您的信息，以帮助我们为您提供更有针对性、更完善的服务(例如：代表我们发出电子邮件或推送通知的通讯服务提供商、接受我们委托进行商业调研的商业咨询服务提供商等。)我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息，我们的合作伙伴及其他第三方亦无权将该共享的个人信息用于任何其他用途；</p> */}
              <p>1、实现本《隐私条款》第二条“我们如何收集和使用信息”部分所述目的；</p>
              <p>2、履行我们在本《隐私条款》或我们与您达成的其他协议中的义务和行使我们的权利；</p>
              {/* <p>5、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他北京文睿网络科技有限公司用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织在法律允许的范围内交换信息；</p> */}
              <p>3、应您合法需求，协助处理您与他人的纠纷或争议；或者为使您可以便捷地实现第三方账户的注册或登录，经您同意或请求后我们向第三方共享您的账户信息；</p>
              <p>4、应未成年人用户的监护人之合法要求而提供的信息；</p>
              {/* <p>8、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p> */}
              <p>5、基于学术研究而提供；</p>
              <p>6、基于符合法律法规的社会公共利益而提供。</p>
              <p>我们仅会出于合法、正当、必要、特定、明确的目的并严格在您授权同意的范围内共享您的信息。对我们与之共享信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本《隐私条款》以及其他任何相关的保密和安全措施来处理信息。</p>
              <p>（二）转让</p>
              <p>1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让、业务重组或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私条款》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
              <p>2、在获得您的明确同意后，我们才会向其他方转让您的信息。</p>
              <p>（三）披露</p>
              <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：</p>
              <p>1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
              <p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
              <p>四、我们如何保留、储存和保护信息</p>
              <p>
                <br/>
              </p>
              <p>（一）我们如何保留和储存您的信息</p>
              <p>&nbsp;&nbsp;1、储存地点</p>
              <p>&nbsp; 我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内。以下情形下，我们会在履行了法律规定的义务后，向境外实体提供您的个人信息：</p>
              <p>&nbsp; （1）适用的法律有明确规定；</p>
              <p>&nbsp; （2）获得您的明确授权；或</p>
              <p>&nbsp; （3）您通过互联网进行跨境交易等个人主动行为。</p>
              <p>&nbsp; &nbsp;针对以上情形，我们会确保依据本隐私条款对您的个人信息安全提供足够的保护。</p>
              <p>&nbsp;&nbsp;2、存储期限</p>
              <p>&nbsp; 我们会采取合理可行的措施，尽量避免收集和处理与个人信息无关的信息和数据。同时，我们只会在达成本隐私条款所述目的所需的期限内保留您的个人信息，除非法律有明确的强制留存要求。我们判断个人信息的合理存储期限主要参考以下标准：</p>
              <p>&nbsp; （1）完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉等售后服务需求；</p>
              <p>&nbsp; （2）保证我们为您提供服务的安全和质量；</p>
              <p>&nbsp; （3）您是否同意更长的留存期间；</p>
              <p>&nbsp; （4）我们和您之间是否存在保留期限的其他特别约定。</p>
              <p>&nbsp;&nbsp;3、超期信息的处理</p>
              <p>&nbsp; 在超出存储期限后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
              <p>（二）我们将采取以下手段保护您的信息：</p>
              <p>&nbsp;&nbsp;1、数据安全技术措施</p>
              <p>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。</p>
              <p>在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。</p>
              <p>&nbsp; 2、我们为保护信息采取的其他安全措施</p>
              <p>我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用；我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制；为加强安全意识，我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。</p>
              <p>&nbsp; 3、我们仅允许有必要知晓这些信息的员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。</p>
              <p>&nbsp;&nbsp;4、我们会采取一切合理可行的措施，确保未收集无关的信息。</p>
              <p>&nbsp;&nbsp;5、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时避免使用过于简单的密码，并注意保护您的信息安全。</p>
              <p>&nbsp;&nbsp;6、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
              <p>&nbsp;&nbsp;7、安全事件处置</p>
              <p>&nbsp; 为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</p>
              <p>&nbsp; 在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。</p>
              <p>五、如何管理您的信息</p>
              <p>（一）访问和更新</p>
              <p>我们鼓励您更新和修改您的信息以使其更准确有效。您能访问您的信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充。我们将采取适当的技术手段，尽可能保证您可以访问、更新和更正自己的信息或使用我们所提供的服务时提供的其他信息。同时，在访问、更新、更正前述信息时，我们可能会要求您进行身份验证，以保障信息安全。</p>
              <p>1、账号信息——如果您希望访问或编辑更新您的账户中的个人基本资料信息(例如头像、昵称、个性化签名、修改密码或者绑定账号等)，您可以通过网页“个人中心”页面中的“账号”菜单来执行此类操作(移动端则通过“我的”菜单进行操作)。</p>
              {/* <p>2、课程信息——您可以通过“学习中心”页面中的“课程”菜单了解和查询您参与的各类课程、直播课开课信息以及学习卡片等(移动端则通过“我的”——“课程历史”等菜单进行操作)。</p>
              <p>3、订单信息——您可以通过“学习中心”页面的“订单”菜单去了解和查询您的全部订单信息，包括已支付订单、待支付订单和已关闭的订单(移动端则通过“我的”——“我的订单”进行操作)。</p>
              <p>4、简历信息——通过“学习中心”页面中的“简历”菜单，您可以在我们的平台创建并随时更新您的个人专属简历(移动端则通过“我的”——“我的简历”进行操作)。</p>
              <p>5、各类消息——您可以通过“学习中心”页面的“消息”菜单，接收和查询我们向您发送的全部班级消息和系统自动发送的其他消息(移动端则通过“我的”——“微信学习提醒”和“重要通知”等菜单进行操作)。</p>
              <p>6、答疑——通过“学习中心”页面中的“答疑”菜单，您可以查询和了解您的发帖信息、提出过的问题、发出过的点评、以及其他用户对您的回复信息等(移动端在“我的”页面中亦可以查询到您的发帖和各种回复信息)。</p> */}
              {/* <p>（二）公开与分享</p>
              <p>我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在北京文睿网络科技有限公司服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应(如前述（一）中所列之简历信息、答疑信息等)，通过电子邮件或在北京文睿网络科技有限公司服务中不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。</p> */}
              <p>（二）信息的删除</p>
              <p>您可以通过“（一）访问和更新”中列明的方式和途径去删除您的部分信息。</p>
              <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
              <p>1、如果我们处理个人信息的行为违反法律法规；</p>
              <p>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
              <p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
              <p>4、如果您不再使用我们的产品或服务，或您主动注销了账号；</p>
              <p>5、如果我们永久不再为您提供产品或服务。</p>
              <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。</p>
              <p>当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何其他信息做进一步隔离处理，直到备份可以清除或实现匿名化。</p>
              <p>（四）改变您授权同意的范围或撤回您的授权</p>
              <p>&nbsp; 您可以选择是否披露信息。有些信息是使用北京文睿网络科技有限公司服务所必需的，但大多数其他信息的提供是由您自主决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权，您也可以通过注销账号的方式彻底撤回我们继续收集您个人信息的全部授权。</p>
              <p>&nbsp; 当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展过的信息处理。</p>
              <p>（五）有关敏感信息的提示</p>
              <p>某些信息因其特殊性可能被认为是敏感信息，例如您的手机号等。</p>
              {/* <p>请注意，您在使用北京文睿网络科技有限公司服务中所提供、上传或发布的内容和信息（例如有关您个人活动的照片或信息），可能会泄露您的敏感信息。您需要谨慎地考虑，是否使用北京文睿网络科技有限公司服务披露您的此类敏感信息。</p> */}
              <p>您同意您的敏感信息按本《隐私条款》所述的目的和方式来处理。</p>
              <p>（六）注销账号</p>
              <p>您可以自行在“注销账号”页面（例如，在APP“我的——账号管理——注销账号”）提交账号注销申请。在注销账号前，我们将对您的个人身份、安全状态、设备信息等做必要的提醒和验证。</p>
              <p>在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
              <p>六、未成年人信息的处理和保护</p>
              <p>根据相关法律法规，不满十八周岁的未成年人参加网上活动使用我们提供的产品及服务前应事先取得家长或其法定监护人（以下简称&quot;监护人&quot;）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息；对于有事实证明存在未成年人在未取得监护人同意的情况下注册使用了我们的产品、服务的情形，我们会在与监护人协商的前提下尽快删除此类个人信息。</p>
              <p>对于不满十四周岁未成年人的个人信息，我们采取了如下进一步保护措施：</p>
              <p>1、当您作为监护人为被监护的未满十四周岁未成年人选择使用我们提供的相关服务时，我们可能需要向您收集被监护的未成年人个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集未成年人个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用晒单、评价及问答功能分享相关信息时可能会主动向我们提供未成年人个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护未成年人个人信息安全。若未成年人本人需要注册或使用我们的产品、服务的，您应正确引导并予以监护。</p>
              <p>2、对于收集到的未成年人个人信息，我们除遵守本隐私条款关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对此类个人信息进行删除或匿名化处理。</p>
              <p>3、未成年人或其监护人有权随时访问和更正未成年人个人信息，还可以向我们提出更正和删除的请求。如您对未成年人个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们，我们会随时为您提供帮助。</p>
              <p>七、通知和修订</p>
              <p>我们可能适时修改本《隐私条款》的条款，该等修改构成本《隐私条款》的一部分。对于重大变更(例如我们服务模式的重大变化、我们因并购重组导致信息持有方发生变化、我们的联络方式发生变化等)，我们会提供更显著的通知，您可以选择停止使用北京文睿网络科技有限公司服务；在该种情况下，如您仍然继续使用北京文睿网络科技有限公司服务的，即表示同意受经修订的本《隐私条款》的约束。</p>
              <p>任何修改都会将您的满意度置于首位。我们鼓励您在每次使用北京文睿网络科技有限公司服务时都查阅我们的隐私条款。</p>
              <p>我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</p>
              <p>最后，您必须对您的账号和密码信息负有保密义务。任何情况下，请小心妥善保管。</p>
              <p>八、如何联系我们</p>
              <p>如您有关于网络信息安全的投诉和举报，或您对本《隐私条款》、对与您个人信息相关的事宜有任何问题、意见或建议，以及有关本《隐私条款》所述隐私措施的问题，您可以通过如下途径与我们联系，我们会尽快给予回复。</p>
              <p>&nbsp;</p>
              <p>联系地址：北京市海淀区琨御府东区8号楼&nbsp;北京文睿网络科技有限公司</p>
              <p>QQ：483374383</p>
              <p>联系邮箱：contact@bjwenrui.com&nbsp;</p>
              <p>如果您对我们的回复不满意，特别是认为我们的个人信息处理行为损害了您的合法权益的，您还可以向网信、电信、公安及工商等监管部门进行投诉。&nbsp;</p>
              <p>
                <br/>
              </p>
          </div>
        )
    }
}
