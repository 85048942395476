
import React, { Component } from 'react'

export default class Metta extends Component {
    render() {
        return (
          <head>
            <title>北京文睿网络科技有限公司_软件开发_软件定制_教育咨询</title>
            <meta name="keywords" content="APP开发公司、小程序开发公司、职业教育产品研发、职业教育软件开发、北京APP开发公司、北京小程序开发公司、技术团队搭建" />
            <meta name="description" content="北京文睿网络科技有限公司是一家专业投身于互联网软件研发工作的专业型公司，公司业务更加倾向于教育产品研发，主要服务于国内知名高校，帮助高校建设智慧校园一体化平台，助力教育服务供给模式升级和教育治理水平提升，发展基于互联网的教育服务新模式！联系QQ：483374383！" />
          </head>
        )
  }
}