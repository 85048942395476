import React, { Component } from 'react'
// import Header from '../Common/Header'
import Menu from '../Common/Menu'
import Footer from '../Common/Footer'
import Banner from './banner'
import Info from './info'
import Service from './service'
import './index.css';
export default class shegong extends Component {
    render() {
        return (
            <div className="boxed_wrapper"> 
                {/* <Header></Header> */}
                <Menu></Menu>
                <Banner></Banner>
                <Info></Info>
                <Service></Service>
                <Footer></Footer>
            </div>
        )
    }
}
