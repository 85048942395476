import React, { Component } from 'react'

export default class service extends Component {
    render() {
        return ( 
            <section className="why-choose sec-padd">
                <div className="container">
                <div className="section-title center">
                    <h2>我们的服务</h2>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="item">
                        <figure className="img-box"> <a href="#!"><img src="/images/1.jpg" className="s-cover" alt=""/></a>
                        <div className="overlay-box">
                            <div className="inner-box">
                                <div className="icon_box"> <span className="icon-graphic"></span> </div>
                                <h4>网站定制</h4>
                                <div className="text">
                                    <p>个人、商业、企业、娱乐、行业、社区、门户、论坛......</p>
                                    <p>品牌网站、手机网站、响应式网站、功能性管理网站量身打造</p>
                                </div>
                            </div>
                        </div>
                        </figure>
                    </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="item">
                        <figure className="img-box"> <a href="#!"><img src="/images/2.jpg" className="s-cover"  alt=""/></a>
                        <div className="overlay-box">
                            <div className="inner-box">
                            <div className="icon_box"> <span className="icon-layers"></span> </div>
                            <h4>小程序定制</h4>
                            <div className="text">
                                <p>触达9.8亿微信用户新路径</p>
                                <p>轻量级，实时更新，跨平台无需按装，开发成本低，小程序抢占千亿级市场</p>
                            </div>
                            </div>
                        </div>
                        </figure>
                    </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="item">
                        <figure className="img-box"> <a href="#!"><img src="/images/3.jpg" className="s-cover" alt=""/></a>
                        <div className="overlay-box">
                            <div className="inner-box">
                            <div className="icon_box"> <span className="icon-computer"></span> </div>
                            <h4>APP定制</h4>
                            <div className="text">
                                <p>体验设计+技术开发</p>
                                <p>提供全方位技术服务，为每一个客户量身打造定制化产品，满足多平台开发需求</p>
                            </div>
                            </div>
                        </div>
                        </figure>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}
