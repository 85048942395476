import React, { Component } from 'react'

export default class Service extends Component {
    render() {
        return (
            <section className="growth-service style-2 sec-padd">
                <div className="container">
                <div className="section-title center">
                    <h2>服务中心</h2>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="item center">
                        <div className="icon_box"> <span className="icon-mathematics"></span> </div>
                        <h4>网站定制</h4>
                        <p>品牌网站、手机网站、响应式网站、功能性管理网站量身打造</p>
                    </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="item center">
                        <div className="icon_box"> <span className="icon-science"></span> </div>
                        <h4>小程序定制</h4>
                        <p>轻量级，实时更新，跨平台无需按装，开发成本低，小程序抢占千亿级市场</p>
                    </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="item center">
                        <div className="icon_box"> <span className="icon-science-1"></span> </div>
                        <h4>APP定制</h4>
                        <p>提供全方位技术服务，为每一个客户量身打造定制化产品，满足多平台开发需求</p>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}
