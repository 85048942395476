import React, { Component } from 'react'

export default class about extends Component {
    render() {
        return (
            <section className="about-faq sec-padd">
                <div className="container">
                <div className="section-title center">
                    <h2>关于我们</h2>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="about-info">
                        <h4>我们专注提供软件定制开发服务</h4>
                        <br/>
                        <div className="text">
                        <p>致力于为企业提供定制化互联网解决方案，APP开发、功能网站策划开发、业务系统开发、软件外包等服务。</p>
                        <br/>
                        <p>致力于为各行业企业提供微信定制服务，主要包括微商城、微营销、微社交、微网站等服务内容。</p>
                        <br/>
                        <p>帮助高校建设智慧校园一体化平台，助力教育服务供给模式升级和教育治理水平提升，发展基于互联网的教育服务新模式！</p>
                        <br/>
                        </div>
                        {/* <div className="link_btn"><a href="/439/a/guanyuwomen/" className="thm-btn">了解更多</a>
                        <div className="sign"><img src="/images/sign.jpg" alt=""/></div>
                        </div> */}
                    </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="accordion-box"> 
                        {/* <!--Start single accordion box--> */}
                        <div className="accordion animated out" data-delay="0" data-animation="fadeInUp">
                        <div className="acc-btn">
                            <p className="title">我们专注提供软件定制开发服务</p>
                            <div className="toggle-icon"> <span className="plus fa fa-angle-right"></span><span className="minus fa fa-angle-down"></span> </div>
                        </div>
                        <div className="acc-content">
                            <div className="text">
                            <p>我们专注提供软件定制开发服务，让我们用产品和服务为您带来无尽的价值。 </p>
                            </div>
                        </div>
                        </div>
                        {/* <!--Start single accordion box--> */}
                        <div className="accordion animated out" data-delay="0" data-animation="fadeInUp">
                        <div className="acc-btn">
                            <p className="title">涉足行业广泛，各端支持</p>
                            <div className="toggle-icon"> <i className="plus fa fa-angle-right"></i><i className="minus fa fa-angle-down"></i> </div>
                        </div>
                        <div className="acc-content">
                            <div className="text">
                            <p> 面向电商、教育、建筑等行业，支持建设手机APP、微信/百度/支付宝小程序等各类移动应用 </p>
                            </div>
                        </div>
                        </div>
                        {/* <!--Start single accordion box--> */}
                        <div className="accordion animated out" data-delay="0" data-animation="fadeInUp">
                        <div className="acc-btn">
                            <p className="title">对于教育行业有非常深的研究和成果</p>
                            <div className="toggle-icon"> <i className="plus fa fa-angle-right"></i><i className="minus fa fa-angle-down"></i> </div>
                        </div>
                        <div className="acc-content">
                            <div className="text">
                            <p> 针对学历教育、非学历教育，高等教育、开放教育、职业教育产品支持丰富 </p>
                            </div>
                        </div>
                        </div>
                        {/* <!--Start single accordion box--> */}
                        <div className="accordion animated out" data-delay="0" data-animation="fadeInUp">
                        <div className="acc-btn">
                            <p className="title">行业口碑排名前茅，建站技术有保障</p>
                            <div className="toggle-icon"> <i className="plus fa fa-angle-right"></i><i className="minus fa fa-angle-down"></i> </div>
                        </div>
                        <div className="acc-content">
                            <div className="text">
                            <p> 建立一个属于自己的网站并实现盈利。涉及网站搭建，网站SEO等。 </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}
