import './App.css';
import React  from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Index from './compnents/Index';
import About from './compnents/About/index';
import RuitikuShegong from './compnents/Ruitiku/shegong';
import PrivateAgreement from './compnents/Policy/privateAgreement';
import RegisterAgreement from './compnents/Policy/registerAgreement';
import PayAgreement from './compnents/Policy/payAgreement';
// const Index = lazy(() => import('./compnents/Index'));
// const About = lazy(() => import('./compnents/About/index'));
function App() {
  return (    
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Index}/>
            <Route path="/about" component={About}/>
            <Route path="/ruitiku.shegong" component={RuitikuShegong}/>
            <Route path="/privateAgreement" component={PrivateAgreement}/>
            <Route path="/registerAgreement" component={RegisterAgreement}/>
            <Route path="/payAgreement" component={PayAgreement}/>
          </Switch>
        </div>
      </Router>
  );
}

export default App;
