import React, { Component } from 'react'
import sy from './images/sy.jpg'
import down from './images/down.png'
export default class Banner extends Component {
    render() {
        return (
            <div>
                <div className="has-base-color-overlay text-center" style={{backgroundColor: "rgb(124, 143, 239)", position: 'relative'}}>
                    <div className="container">
                        {/* <div className="box">
                            <h3>社工锐题库</h3>
                        </div> */}
                        <div className="banner-width">
                            {/* <img className="banner-bg" src="static/images/downApp/banner.jpg"/> */}
                            <div className="center-box head-info">
                                <div>
                                    <img src={sy} className="home-img" alt=""/>
                                </div>
                                <div>
                                    <img src={down} className="home-img" alt=""/>
                                </div>
                            </div>
                        </div>
                    {/* <!-- /.box -->  */}
                    </div>
                    {/* <!-- /.container -->  */}
                </div>
                <div className="breadcumb-wrapper">
                    <div className="container">
                    <div className="pull-left">
                        <ul className="list-inline link-list">
                        <a href='/'>主页</a> &gt; <a href='/ruitku.shegong'>社工锐题库</a>
                        </ul>
                        {/* <!-- /.list-line -->  */}
                    </div>
                    {/* <!-- /.pull-left -->  */}
                    
                    </div>
                    {/* <!-- /.container -->  */}
                </div>
            </div>
        )
    }
}
// 社会工作者锐题库
// 为社会工作者考试学员提供最全面、最精准的在线题库，刷真题、押题，提高通过率。
// https://www.bjwenrui.com
// 应用需要使用微信三方登录
// com.ruitiku.shegong
// com.ruitiku.shegong
// https://www.bjwenrui.com/