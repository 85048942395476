import React, { Component } from 'react'
import mr from './images/mr.jpg'
import zt from './images/zt.jpg'
import yt from './images/yt.jpg'
export default class Service extends Component {
    render() {
        return (
            <>
                <div className="tab-box clearfix">
                    <span className="tab-item c1"></span>
                    <span className="tab-item c2">每日特训</span>
                    <span className="tab-item c3">历年真题</span>
                    <span className="tab-item c4">押题试卷</span>
                    <span className="tab-item c5"></span>
                </div>
                <div className="center-box tab-img clearfix">
                    <div className="">
                        <img className="" src={mr}/>
                    </div>
                    <div className="">
                        <img className="" src={zt}/>
                    </div>
                    <div className="">
                        <img className="" src={yt}/>
                    </div>
            
                </div>
            </>
        )
    }
}
