import React, { Component } from 'react'
import img3 from './images/3.png'
export default class Anli extends Component {
    render() {
        return (
            <section className="about-faq sec-padd">
               <div className="center-box">
                    <div className="ver-middle  clearfix">
                        <div className="fl">
                            <p className="text-b">
                                APP/微信小程序，同步学习进度
                            </p>
                            <p className="english-grey">
                                Cell phone wxmp synchronization
                            </p>
                            <p className="mess-de">
                            为社会工作者考试学员提供最全面、最精准的在线题库，刷真题、押题，提高通过率。
                            </p>
                            <p className="mess-de">
                                只要一个账号，APP、小程序随时切换登录 <br/>
                                同一题库，多端同步学习刷题
                            </p>
                        </div>
                        <div className="rt">
                            <img src={img3}></img>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
