import React, { Component } from 'react'
import './index.css';
export default class RegisterAgreement extends Component {
    render() {
        return (          
            <div className="register"> 
              {/* <div className='title'>用户注册协议</div> */}
              <p>欢迎申请并使用北京文睿网络科技有限公司（以下统称“
                <strong>北京文睿网络科技有限公司</strong>”或“
                <strong>本公司</strong>”）所提供的服务。
                <strong>请您（以下又称为“用户”）仔细阅读以下全部内容（特别是以粗体下划线加粗标注的内容）</strong>。如用户不同意本服务协议的任意内容，请勿注册或使用北京文睿网络科技有限公司服务。如用户通过进入注册程序并确认点击“马上注册”，即表示用户与本公司已达成协议，自愿接受本服务协议的所有内容。此后，用户不得以未阅读或不了解本服务协议内容作任何形式的抗辩。</p>
              <p>
                <br/>
              </p>
              <p>
                <strong>1.&nbsp;服务条款的确认、接纳及修改</strong>
              </p>
              <p>北京文睿网络科技有限公司服务涉及到的所有产品的所有权以及相关软件的知识产权归本公司所有。本服务协议的效力范围及于本公司所提供的一切产品和服务，用户在享受本公司任何单项服务时，应当受本服务协议的约束。</p>
              {/* <p>当用户使用北京文睿网络科技有限公司针对各特定课程所提供的单项服务时(以下简称“单项服务”)，应以本公司认可的方式同意该单项服务的服务条款、特别制定的服务协议以及本公司在该单项服务中发出的各类公告信息(下列简称
                <strong>“单项条款”</strong>），在此情况下单项条款与本服务协议同时对用户产生效力。
                <strong>若单项条款与本服务协议存在任何冲突或不一致的，则在单项条款约束范围内应以单项条款为准。</strong>
              </p> */}
              <p>本公司有权在必要时通过在网页上发出公告等合理方式修改本服务协议以及各单项服务的相关条款。用户在享受各项服务时，应当及时查阅了解最新修改的内容，并自觉遵守本服务协议以及该单项服务的相关条款。用户如继续使用本服务协议涉及的服务，则视为对修改内容的同意和接受，当发生有关争议时，以最新的服务协议为准；用户在不同意修改内容的情况下，有权停止使用本服务协议涉及的服务。若您仍继续使用北京文睿网络科技有限公司服务的，即视为您已了解并完全同意修改后的各项协议内容。</p>
              <p>
                <br/>
              </p>
              <p>
                <strong>2.&nbsp;服务内容、范围</strong>
              </p>
              {/* <p>北京文睿网络科技有限公司目前向用户提供的服务包括但不限于观看视频、签署协议、购买课程、获取直播课程信息准时参加直播课程学习、获取相关学习资料等。本公司运用自己的信息网络渠道通过国际互联网络为用户提供各项服务。用户必须：</p> */}
              <p>北京文睿网络科技有限公司目前向用户提供的服务包括但不限于刷题练习、查看试卷、获取相关学习资料等。本公司运用自己的信息网络渠道通过国际互联网络为用户提供各项服务。用户必须：</p>
              <p>(1)&nbsp;自备相关设备，如个人电脑、手机或其他上网设备等；</p>
              <p>(2)&nbsp;承担个人上网相关的费用。</p>
              <p>
                <br/>
              </p>
              <p>
                <strong>3.&nbsp;北京文睿网络科技有限公司用户帐号注册规则</strong>
              </p>
              <p>(1)&nbsp;帐号注册资料包括但不限于用户提交录入的手机号码、密码、为完成注册而由系统通过短信自动发送的验证码、注册或更新北京文睿网络科技有限公司帐号时输入的所有信息以及用户使用北京文睿网络科技有限公司各单项服务时输入、提供的所有信息。</p> 
              <p>
                <span style={{textDecoration: 'underline'}}>
                  <strong>用户在任何情况下皆应对其帐号注册资料严格保密，不得提供、泄露给他人，若因用户违反保密操作要求所导致的任何后果由用户自己负责。在申请注册过程中，用户可能因自身特殊原因或特别情形而就某一注册环节或步骤寻求北京文睿网络科技有限公司或其工作人员的支持和帮助，在此情形下，用户提出的要求和指示即视为其对北京文睿网络科技有限公司、北京文睿网络科技有限公司工作人员的委托、授权，北京文睿网络科技有限公司及其工作人员基于用户委托和指示所做的任何操作亦视为系其接受用户委托后作出，因此其相应的后果和责任也应由用户自行承担。</strong>
                </span>
              </p>
              <p>(2)&nbsp;用户在注册北京文睿网络科技有限公司帐号时承诺遵守法律法规、国家利益、公民合法权益、公共秩序、社会主义道德和信息真实性等原则，不得在北京文睿网络科技有限公司帐号注册资料中出现违法和不良信息，且用户保证其在注册和使用帐号时，不得有以下情形：</p>
              <p>1)&nbsp;违反宪法或法律法规规定的；</p>
              <p>2)&nbsp;危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
              <p>3)&nbsp;损害国家荣誉和利益的，损害公共利益的；</p>
              <p>4)&nbsp;煽动民族仇恨、民族歧视，破坏民族团结的；</p>
              <p>5)&nbsp;破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
              <p>6)&nbsp;散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
              <p>7)&nbsp;散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
              <p>8)&nbsp;侮辱或者诽谤他人，侵害他人合法权益的；</p>
              <p>9)&nbsp;含有法律、行政法规禁止的其他内容的。</p>
              <p>(3)&nbsp;您同意遵守中华人民共和国相关法律法规的所有规定，并对以任何通过您的密码和您的帐号来使用北京文睿网络科技有限公司服务的任何行为及其结果承担全部责任。如您的行为违反国家法律和法规的任何规定，有可能构成违法犯罪的，您将被追究法律责任，同时本公司亦有权不经任何事先通知而暂停或终止向您提供服务。</p>
              <p>(4)&nbsp;用户同意，其提供的真实、准确、合法的北京文睿网络科技有限公司帐号注册资料是作为认定用户个体与其北京文睿网络科技有限公司帐号的关联性以及用户身份确认的唯一证据。
                <span style={{textDecoration: 'underline'}}>
                  <strong>本公司提醒用户，用户注册北京文睿网络科技有限公司帐号或更新注册信息时所填写的相关内容，在注册北京文睿网络科技有限公司帐号成功或补充填写成功后若再行修改的，本公司出于安全考虑将设置相关的修改程序，因此请用户慎重填写提交各类注册信息。</strong>
                </span>若本公司有合理理由怀疑您提交的相关资料或信息属于恶意程序或恶意操作，则本公司有权暂停或终止向您的帐号提供服务，并有权拒绝您于现在和将来使用本公司所提供服务之全部或任何部分。</p>
              <p>(5)
                <span style={{textDecoration: 'underline'}}>
                  <strong>&nbsp;为使用户及时、全面了解本公司提供的各项服务，用户同意，本公司有权通过您在注册或使用我们的服务时自愿提供的有效联系方式（包括您的手机号码等）向您发送、展示、传达各种信息（包括商业与非商业信息），而无需另行获得用户的特别同意。信息的具体发送及展示形式、频次及内容等以北京文睿网络科技有限公司实际提供的为准，如您不愿意继续接收此类信息，您亦有权通过北京文睿网络科技有限公司提供的相应的退订功能进行退订。</strong>
                </span>
              </p>
              <p>(6)&nbsp;用户同意与注册、使用北京文睿网络科技有限公司帐号相关的一切资料、数据和记录，包括但不限于北京文睿网络科技有限公司帐号、注册资料、所有登录和操作记录和相关的使用统计数字、产品客户服务记录等归北京文睿网络科技有限公司所有。
                <span style={{textDecoration: 'underline'}}>
                  <strong>发生争议时，用户同意以北京文睿网络科技有限公司的系统数据为准</strong>
                </span>，北京文睿网络科技有限公司保证该数据的真实性。</p>
              <p>(7）为方便您使用北京文睿网络科技有限公司提供的服务，
                <strong>您同意并授权北京文睿网络科技有限公司将您在账户注册和使用北京文睿网络科技有限公司服务过程中提供、形成、发布的信息在北京文睿网络科技有限公司内部进行共享和传递</strong>。</p>
              <p>
                <strong>4.&nbsp;帐号、密码及相关安全注意事项</strong>
              </p>
              <p>在完成北京文睿网络科技有限公司要求的注册程序并成功注册之后，您可使用您的帐号和密码，登录并使用北京文睿网络科技有限公司向您提供的各项服务。保护您的帐号安全，是北京文睿网络科技有限公司的责任、同时亦是您自身的义务。</p>
              <p>
                <span style={{textDecoration: 'underline'}}>
                  <strong>您应对您所有通过帐号及密码所做的操作、所进行的活动负完全的责任</strong>
                </span>。您同意：</p>
              <p>(1)&nbsp;一旦发现您的北京文睿网络科技有限公司帐号遭到未获授权的使用，或者发生其它任何安全问题时，您将立即通知北京文睿网络科技有限公司；</p>
              <p>(2)&nbsp;一旦北京文睿网络科技有限公司发现您的帐号存在异常状况的(包括但不限于异地登陆、IP地址异常变动、发送注册信息变更请求等)，则北京文睿网络科技有限公司有权依据其合理判断采取相应措施(包括但不限于要求用户进行手机绑定等)以保护用户帐号安全；</p>
              <p>(3)&nbsp;若您未保管好自己的帐号和密码，因此而产生的任何损失或损害，北京文睿网络科技有限公司不应承担任何责任；因此对您、北京文睿网络科技有限公司或其他第三方造成任何损害的，将由您负全部责任。</p>
              <p>
                <br/>
              </p>
              <p>
                <strong>5.&nbsp;北京文睿网络科技有限公司隐私政策</strong>
              </p>
              <p>您提供的注册登记资料及由北京文睿网络科技有限公司保留的有关您的若干其它资料将受到中国有关隐私保护的法律法规以及本公司《隐私条款》(详情请见本公司官网)之保护及规范。</p>
              <p>
                <br/>
              </p>
              <p>
                <strong>6.&nbsp;知识产权保护</strong>
              </p>
              <p>本公司向用户提供的服务包括但不限于：文字、软件、图片、图表等。所有这些内容均属于北京文睿网络科技有限公司所有，并受版权、商标、专利和其它财产所有权法律的保护。所以，
                <span style={{textDecoration: 'underline'}}>
                  <strong>用户只能在本公司授权下才能为自用目的合理使用这些内容，而不能擅自复制、再造这些内容、或创造与这些内容有关的衍生作品或产品</strong>
                </span>。</p>
              <p>北京文睿网络科技有限公司、以及本公司的其他标志及产品、服务的名称，均为本公司之商业标识（以下简称“
                <strong>北京文睿网络科技有限公司标识</strong>”）。
                <span style={{textDecoration: 'underline'}}>
                  <strong>未经本公司事先书面同意，您不得将北京文睿网络科技有限公司标识以任何方式展示或使用或作其他处理，也不得向他人表明或误导他人相信您有权展示、使用、或其他有权处理北京文睿网络科技有限公司标识</strong>
                </span>。</p>
              <p>同时，本公司尊重知识产权并注重保护用户享有的各项权利。在本公司提供服务过程中，用户可能需要通过上传、发布等各种方式向本公司或通过北京文睿网络科技有限公司提供相关内容(包括但不限于用户通过本公司或本公司授权人员享有处置权之BBS、电子公告牌、即时通信工具及聊天群、组等)。在此情况下，用户仍然享有此等内容的完整知识产权。
                <span style={{textDecoration: 'underline'}}>
                  <strong>用户在提供内容时将授予本公司一项全球性的免费许可，即允许本公司使用、传播、复制、修改、再许可、翻译、创建衍生作品、出版、表演及展示此等内容</strong>
                </span>。</p>
              <p>
                <br/>
              </p>
              {/* <p>
                <strong>7.&nbsp;针对付费类课程产品和服务的特别约定</strong>
              </p>
              <p>北京文睿网络科技有限公司基于自身的经营自主权，自主决定向用户提供丰富的课程产品和服务项目供用户选择，用户可根据自身实际需求决定选择免费服务项目或是有偿、付费服务项目。针对有偿课程产品及服务项目，用户请注意下述特别事项：</p>
              <p>(1)&nbsp;购买：</p>
              <p>您可通过本公司官网或其他经本公司授权之第三方销售渠道订购北京文睿网络科技有限公司课程产品、服务，并完成费用支付。
                <span style={{textDecoration: 'underline'}}>
                  <strong>根据您所订购产品的具体信息(以订购当时北京文睿网络科技有限公司官网公示为准)，包括但不限于课程内容、课程安排、价格、退款政策等，在您支付完成后将由北京文睿网络科技有限公司系统后台生成1份电子订单，该电子订单系本协议之有效组成部分，是确定用户与北京文睿网络科技有限公司各自权利义务的有效依据</strong>
                </span>。支付完成后您即可根据所购买课程享受北京文睿网络科技有限公司提供的各项服务。北京文睿网络科技有限公司针对部分课程，可能会需要向您邮寄提供教材、讲义等课程资料，因此请您在提交订单前务必仔细核对收货人、收货地址及联系电话等信息。
                <span style={{textDecoration: 'underline'}}>
                  <strong>收货人与用户本人不一致的，收货人的行为和意思表示即视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任</strong>
                </span>。</p>
              <p>(2)&nbsp;服务、产品的提供：</p>
              <p>
                <span style={{textDecoration: 'underline'}}>
                  <strong>本公司针对不同的课程产品分别设置有其对应的服务期，服务期自用户完成该课程产品的支付之时起算，</strong>
                </span>
                <span style={{textDecoration: 'underline'}}>
                  <strong>用户应于服务期内完成所购课程产品对应的全部课程的学习。</strong>
                </span>
              </p> */}
              {/* <p>
                <span style={{textDecoration: 'underline'}}>
                  <strong>为提升用户体验，用户</strong>
                </span>
                <span style={{textDecorationLine: 'underline'}}>
                  <strong>所购买课程结课后，本公司在服务期内有权(但无义务)视具体情况向其提供其他辅助服务或产品以丰富用户体验，与辅助服务或产品相关的权利义务及具体信息请见具体课程说明或介绍</strong>
                </span>。</p>
              <p>此外，北京文睿网络科技有限公司
                <span style={{textDecoration: 'underline'}}>
                  <strong>针对部分特殊课程产品提供有条件的延长服务期服务(详情请见具体课程产品的页面信息)</strong>
                </span>，由用户在符合下述条件的前提下、于服务期结束后向北京文睿网络科技有限公司提出延长申请，且仅支持逐年申请。除非各产品对应的单项条款有不同规定，否则，申请延长服务期的，一般应同时符合以下四项条件：
                <br/></p>
              <p>a)&nbsp;在原服务期内任意完整一期直播课的出勤率达到80%及以上（特指直播出勤、不含直播回放）；</p>
              <p>b)&nbsp;在原服务期内北京文睿网络科技有限公司题库中的历年真题卷，内部押题卷做题完成率达到80%及以上；</p>
              <p>c)&nbsp;在原服务期内参加课程对应的任意一期考试，考试未通过(不及格或未达标)、且考试成绩不低于北京文睿网络科技有限公司要求的分数标准(由于考试安排及情况的不断变化，因此每一产品具体的分数标准请以用户购买课程时该课程产品页面明示的分数信息为准)，同时提交考试未通过的官方证明；</p>
              <p>d)&nbsp;申请需于考试成绩公布后3个月内提出，否则即视为放弃该申请延长的权利。</p>
              <p>
                <span style={{textDecoration: 'underline'}}>
                  <strong>一个帐号只能由一个用户使用，不允许多个用户使用同一个帐号，若本公司有合理理由认为可能存在用户帐号被盗取、被盗用情形的，则本公司有权将此帐号暂时或永久作无效处理并保留追究法律责任的权利。用户将帐号借给他人使用的，用户应承担由此产生的全部责任，并与实际使用人承担连带责任</strong>
                </span>。用户在享受直播课程服务过程中，应自觉维护直播课堂秩序，不得作出、参与任何影响课堂正常秩序的行为。</p>
              <p>(3)&nbsp;退款</p>
              <p>基于各单项服务或课程产品的特点，
                <strong>
                  <span style={{textDecoration: 'underline'}}>北京文睿网络科技有限公司针对不同的单项服务和产品制定了相应的退款规则，具体以用户订购当时北京文睿网络科技有限公司官网所公示的各项服务之退款服务规则和条件、各单项条款的规定为准</span>
                </strong>。请您在订购前务必仔细阅读并保证已充分了解了拟订购服务的退款条件及规则，一旦提交订购请求即视为您已经完全了解并同意该退款规则。</p>
              <p>(4)&nbsp;转班</p>
              <p>在您所购买的课程开课后至结课前，如您对授课老师不满意，服务期内您可以享受免费转班的权利。</p>
              <p>与转班相关的规则、流程、具体要求等，请见北京文睿网络科技有限公司官网“帮助中心”有关转班制度的详情页面；如各产品对应的单项条款有不同规定的，则以该单项条款的特别规定为准。</p>
              <p>(5)&nbsp;重修</p>
              <p>
                <span style={{textDecoration: 'underline'}}>
                  <strong>为更好的满足应试类产品用户的实际需求，针对某些特定的课程产品，如用户完成全部课程学习后仍然考试不合格、未通过考试的，服务期内，北京文睿网络科技有限公司依据一定的规则和条件向此类用户提供免费重修服务涉及该服务的产品范围及相关的规则、条件、申请流程等具体信息，请见产品页面公开信息</strong>
                </span>。与重修相关的规则、流程、具体要求等，请见北京文睿网络科技有限公司官网“帮助中心”有关重修制度的详情页面；如各产品对应的单项条款有不同规定的，则以单项条款的特别规定为准。对于重修用户，北京文睿网络科技有限公司不赠送或者免费提供任何教材，如有需要则需另行付费购买。</p>
              <p>
                <br/>
              </p> */}
              {/* <p>
                <strong>7.&nbsp;关于求职辅助服务的特别约定</strong>
              </p>
              <p>为更好的服务用户，北京文睿网络科技有限公司开设有求职帮助频道(zhaopin.duia.com)以发布相关招聘岗位信息，同时亦允许用户进行简历发布及简历投递等相关操作。用户明确知悉并同意：</p>
              <p>(1)&nbsp;不得从事、存在以下任一行为或情形：</p>
              <p>a)&nbsp;发布信息(包括通过简历或求职频道其他功能模块发布，下同)的完整性、真实性、准确性、及时有效性存在任何瑕疵；</p>
              <p>b)&nbsp;发布信息与用户个人身份信息之间的指向存在不确定性、非唯一性；</p>
              <p>c)&nbsp;发布信息不符合简历性质、特征或求职意向不明，或存在商业营销推广信息；</p>
              <p>d)&nbsp;发布信息涉嫌侵犯第三方合法权利；</p>
              <p>e)&nbsp;北京文睿网络科技有限公司根据实际情况酌情判定不适宜发布、展现的其他信息。</p>
              <p>用户违反上述保证、发布信息存在以上任一情形的，北京文睿网络科技有限公司有权对用户所发布信息、用户账号做任何必要之处置(包括但不限于违规信息删除、下线、账号暂停使用或永久封禁等)。因此造成任何其他不利后果的(如被用人单位不予录用或录用后解除劳动关系等)，由用户自行承担；给北京文睿网络科技有限公司造成任何损失或损害的，北京文睿网络科技有限公司亦有权追究其相应法律责任。</p>
              <p>
                <span style={{textDecoration: 'underline'}}>(2)
                  <strong>&nbsp;用户明确知悉并了解，北京文睿网络科技有限公司作为信息发布平台，仅对其根据用户(包括求职者和用人单位)上传至其运营平台并要求发布的信息做形式和技术层面的审核以保证能顺利发布上线，不会、也没有能力针对海量信息内容本身做实质性审查、内容修改或指导等，因此不对所发布登载信息的真实性、完整性、准确性、及时有效性做任何担保、保证，也不对信息发布后是否能成功实现发布者的意愿和目的做任何承诺、担保、保证，请用户谨慎判断、辨别相关信息并作出决策。用户在通过北京文睿网络科技有限公司得到资讯和信息后，与信息发布方所进行的任何活动(包括联系、沟通、面试、谈判、入职、建立劳动关系等)均系双方依据各自独立意志所开展，双方之间如发生任何纠纷的，皆与北京文睿网络科技有限公司无关，北京文睿网络科技有限公司不承担任何责任</strong>
                </span>。</p>
              <p>
                <span style={{textDecoration: 'underline'}}>(3)&nbsp;
                  <strong>用户明确知悉并确认同意：为向用户提供更为丰富完善的服务、提升用户求职成功率，北京文睿网络科技有限公司有权收集和使用(包括向招聘单位提供)用户在使用本网站服务的过程中发布、留存的相关信息、产生的数据、使用痕迹等各类信息，包括但不限于用户创建发布简历过程中提供的信息、用户活跃度、用户在北京文睿网络科技有限公司接受培训进行学习的情况等</strong>
                </span>。</p>
              <p>
                <br/>
              </p> */}
              <p>
                <strong>7.&nbsp;不可抗力</strong>
              </p>
              <p>“不可抗力”指超出本协议双方控制范围，无法预见、无法避免且无法克服、使得本协议任一方部分或者全部不能履行本协议的事件。这类事件包括但不限于地震、台风、洪水、火灾、战争、罢工、暴动、网络中断、政府行为、法律规定或其适用规则的变化、政策调整或变化(包括但不限于考试取消、暂停、举办时间变化等)，或者其他任何无法预见、避免或者控制的事件。</p>
              <p>(1)&nbsp;如任何一方不履行或迟延履行本协议规定的义务是由于不可抗力引发，则该方免除承担违约责任；</p>
              <p>(2)&nbsp;不可抗力事由发生后，若有可能，受不可抗力影响的一方应在合理时间内将有关情况及时通知对方（甲方可以通过网站公告、论坛或即时通讯工具通知、一对一联系的方式通知乙方）。</p>
              <p>不可抗力因素消除后，曾受不可抗力影响而未能履约的一方应尽最大努力恢复履行其在本协议项下的义务。</p>
              <p>
                <br/>
              </p>
              <p>
                <strong>8.&nbsp;其他</strong>
              </p>
              {/* <p>(1)&nbsp;北京文睿网络科技有限公司有随时调整各项服务费用收取标准的权利(已完成支付的订单不受影响)；</p> */}
              {/* <p>(2)&nbsp;由于考试大纲变化、师资变动等原因，北京文睿网络科技有限公司有调整培训内容、授课老师的权利；</p> */}
              <p>(1)&nbsp;由于考试大纲变化等原因，北京文睿网络科技有限公司有调整试卷、高频题库、散题题库内容的权利；</p>
              {/* <p>(3)&nbsp;基于实际情况变化，北京文睿网络科技有限公司有调整课程设置的权利，也有调整服务提供流程的权利(包括但不限于退课、换课、赠课流程等)；</p>
              <p>(4)&nbsp;若您选择第三方支付平台等方式进行费用支付，在支付过程中因您自身原因或第三方支付平台原因造成的异常使其帐号无法正常使用或遭受损失的，北京文睿网络科技有限公司对此概不负责；</p> */}
              <p>(2)&nbsp;本公司不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响本公司在将来行使该权利；</p>
              <p>(3)&nbsp;
                <span style={{textDecoration: 'underline'}}>
                  <strong>本协议根据现行中华人民共和国法律法规制定</strong>
                </span>。如发生协议条款与中华人民共和国法律法规相抵触时，则这些条款将完全按法律法规的规定重新解释，但本协议的其它条款仍对北京文睿网络科技有限公司和用户具有法律约束力。</p>
              <p>(4)&nbsp;
                <span style={{textDecoration: 'underline'}}>
                  {/* <strong>因本协议而发生的任何争议，您和北京文睿网络科技有限公司首先应友好协商解决。协商不成的，双方均一致同意将相关争议提交本协议签订地(即中国北京市大兴区)有管辖权的人民法院管辖</strong> */}
                  <strong>因本协议而发生的任何争议，您和北京文睿网络科技有限公司首先应友好协商解决。协商不成的，双方均一致同意将相关争议提交本协议签订地有管辖权的人民法院管辖</strong>
                </span>。</p>
              <p>
                <br/>
              </p>
            </div>
        )
    }
}
