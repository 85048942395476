import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="main-footer"> 
  
                {/* <!--Widgets Section--> */}
                {/* <div className="widgets-section">
                    <div className="container">
                    <div className="row"> 
                        <!--Big Column-->
                        <div className="big-column col-md-6 col-sm-12 col-xs-12">
                        <div className="row clearfix"> 
                            
                            <!--Footer Column-->
                            <div className="footer-column col-md-6 col-sm-6 col-xs-12">
                            <div className="footer-widget about-widget">
                                <figure className="footer-logo"><a href="/439/"><img src="/images/logo2.png" alt=""/></a></figure>
                                <div className="widget-content">
                                <div className="text">
                                    <p>百度云专业提供网站模板，网页模板，教程培训，程序插件，网站素材等建站资源。我们致力于打造一个优秀的建站资源共享学习平台</p>
                                </div>
                                <div className="link"> <a href="/439/a/guanyuwomen/" className="default_link">More About us <i className="fa fa-angle-right"></i></a> </div>
                                </div>
                            </div>
                            </div>
                            <!--Footer Column-->
                            <div className="footer-column col-md-6 col-sm-6 col-xs-12">
                            <div className="footer-widget links-widget">
                                <div className="section-title">
                                <h3>网站导航</h3>
                                </div>
                                <div className="widget-content">
                                <ul className="list">
                                    <li><a href="/439/">网站首页</a></li>
                                    
                                    <li><a href="/439/a/guanyuwomen/">关于我们</a></li>
                                    
                                    <li><a href="/439/a/anni/">案例产品</a></li>
                                    
                                    <li><a href="/439/a/news/">新闻中心</a></li>
                                    
                                    <li><a href="/439/a/lianxiwomen/">联系我们</a></li>
                                    
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        
                        <!--Big Column-->
                        <div className="big-column col-md-6 col-sm-12 col-xs-12">
                        <div className="row clearfix"> 
                            
                            <!--Footer Column-->
                            <div className="footer-column col-md-6 col-sm-6 col-xs-12">
                            <div className="footer-widget posts-widget">
                                <div className="section-title">
                                <h3>最新新闻</h3>
                                </div>
                                <div className="widget-content"> <div className="post">
                                    <div className="content">
                                    <h4><a href="/439/a/anni/shouji/2015/0912/15.html" target="_blank">5项提高产品设计的交互模式</a></h4>
                                    </div>
                                    <div className="time">2016-11-27</div>
                                </div>
                <div className="post">
                                    <div className="content">
                                    <h4><a href="/439/a/anni/shouji/2015/0912/14.html" target="_blank">导航设计模式的重要意义</a></h4>
                                    </div>
                                    <div className="time">2016-11-27</div>
                                </div>
                </div>
                            </div>
                            </div>
                            
                            <!--Footer Column-->
                            <div className="footer-column col-md-6 col-sm-6 col-xs-12">
                            <div className="footer-widget contact-widget">
                                <div className="section-title">
                                <h3>联系我们</h3>
                                </div>
                                <div className="widget-content">
                                <ul className="contact-info">
                                    <li><span className="icon fa fa-paper-plane"></span>广东省广州市番禺园科技大厦</li>
                                    <li><span className="icon fa fa-envelope"></span> admin@baidu.com</li>
                                    <li><span className="icon fa fa-phone"></span>186 8888 8889</li>
                                </ul>
                                </div>
                                <ul className="social">
                                <li><a href="/"><i className="fa fa-weibo"></i></a></li>
                                <li><a href="/"><i className="fa fa-weixin"></i></a></li>
                                <li><a href="/"><i className="fa fa-qq"></i></a></li>
                                <li><a href="/"><i className="fa fa-renren"></i></a></li>
                                <li><a href="/"><i className="fa fa-skype"></i></a></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div> */}
                
                {/* <!--Footer Bottom--> */}
                <section className="footer-bottom">
                    <div className="container">
                    <div className="pull-left copy-text">
                        <p>Copyright &copy; 北京文睿网络科技有限公司 2024 版权所有</p>
                    </div>
                    {/* <!-- /.pull-right --> */}
                    <div className="pull-right get-text">
                        <ul>
                        <li><a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备2020037200号-1</a></li>
                        </ul>
                    </div>
                    {/* <!-- /.pull-left -->  */}
                    </div>
                    {/* <!-- /.container -->  */}
                </section>
                </footer>
        )
    }
}
