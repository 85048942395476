import React, { Component } from 'react'

export default class Banner extends Component {
    render() {
        return (
            <div>
                <div className="inner-banner has-base-color-overlay text-center" style={{background: "url(/images/about/1.jpg)"}}>
                    <div className="container">
                    <div className="box">
                        <h3>关于我们</h3>
                    </div>
                    {/* <!-- /.box -->  */}
                    </div>
                    {/* <!-- /.container -->  */}
                </div>
                <div className="breadcumb-wrapper">
                    <div className="container">
                    <div className="pull-left">
                        <ul className="list-inline link-list">
                        <a href='/'>主页</a> &gt; <a href='/about'>关于我们</a> &gt;
                        </ul>
                        {/* <!-- /.list-line -->  */}
                    </div>
                    {/* <!-- /.pull-left -->  */}
                    
                    </div>
                    {/* <!-- /.container -->  */}
                </div>
            </div>
        )
    }
}
