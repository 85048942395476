import React, { Component } from 'react'
// import Header from '../Common/Header'
import Menu from '../Common/Menu'
import Footer from '../Common/Footer'
import Banner from './banner'
import Desc from './desc'
import Anli from './anli'
import Service from './service'
export default class index extends Component {
    render() {
        return (
            <div className="boxed_wrapper"> 
                {/* <Header></Header> */}
                <Menu></Menu>
                <Banner></Banner>
                <Desc></Desc>
                <Anli></Anli>
                <Service></Service>
                <Footer></Footer>
            </div>
        )
    }
}
