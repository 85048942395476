import React, { Component } from 'react'

export default class Anli extends Component {
    render() {
        return (
            <section className="three-column sec-padd-top bg">
                <div className="container">
                <div className="section-title center">
                    <h2>案例产品</h2>
                </div>
                <div className="row"> 
                <div className="col-md-4 col-sm-6">
                    <div className="single-our-service">
                        <figure className="img-box"> <a href="https://labtraining.chinacdc.cn/"> <img src="/images/anli/cdc.png" alt="跳转至中国疾病预防控制中心在线培训考评平台" style={{height:"220px", width:"100%"}}/></a>
                        <div className="count">2024</div>
                        </figure>
                        <div className="text-box"> <a href="https://labtraining.chinacdc.cn/">
                        <h4>中国疾病预防控制中心在线培训考评平台</h4>
                        </a>
                        <p>在线组织课程直播/点播视频学习，在线考核，发布证书等</p>
                        </div>
                    </div>
                    </div>
                <div className="col-md-4 col-sm-6">
                    <div className="single-our-service">
                        <figure className="img-box"> <a href="#"> <img src="/images/anli/zxxxpt.png" alt="" style={{height:"220px", width:"100%"}}/></a>
                        <div className="count">2021</div>
                        </figure>
                        <div className="text-box"> <a href="#">
                        <h4>一体化在线教学平台</h4>
                        </a>
                        <p>适用于高校的全面的在线教学平台</p>
                        </div>
                    </div>
                    </div>
            {/* <div className="col-md-4 col-sm-6">
                    <div className="single-our-service">
                        <figure className="img-box"> <a href="https://peixun.bjwenrui.com"> <img src="/images/anli/peixun.png" alt="在线培训管理平台"  style={{height:"220px", width:"100%"}}/></a>
                        <div className="count">2021</div>
                        </figure>
                        <div className="text-box"> <a href="https://peixun.bjwenrui.com">
                        <h4>在线培训管理平台</h4>
                        </a>
                        <p>含招生缴费、教学、领证等于一体</p>
                        </div>
                    </div>
                    </div> */}
            <div className="col-md-4 col-sm-6">
                    <div className="single-our-service">
                        <figure className="img-box"> <a href="#"> <img src="/images/anli/zxkt.png" alt="响应式Web设计的9项基本原则"  style={{height:"220px", width:"100%"}}/></a>
                        <div className="count">2020</div>
                        </figure>
                        <div className="text-box"> <a href="#">
                        <h4>在线课堂</h4>
                        </a>
                        <p>在线直播、音视频互动、聊天等</p>
                        </div>
                    </div>
                    </div>
            </div>
                </div>
            </section>
        )
    }
}
